@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
.privacy-container {
  display: flex;
  flex-direction: column;
  padding: 80px;
  margin-top: 177px;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.32px;
  color: #000000;
  /* text-align: left; */
}
.privacy-container.arabic{
  font-family: "Cairo";
}

.privacy-title {
  font-family: 'Work Sans', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  margin-bottom: 30px; 
}
.privacy-title.arabic{
  font-family:"Cairo" ;
  font-weight: 600;
}

.privacy-mob{
  display: none;
}


@media screen and (max-width: 767px) {
  .privacy-web{
    display: none;
  }
  .privacy-mob{
    display: block;
    padding-top: 135px;
    padding-left:20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .privacy-title {
    font-size: 24px;
  }
  

  .privacy-container-mob {
    display: flex;
    flex-direction: column;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #000000;
    /* text-align: left; */
  }
  .privacy-container-mob.arabic{
    font-family: "Cairo";
  }
}