.verification-container{
    margin-top: 177px;
    display: flex;
    flex-direction: column !important;
    align-items: center; 
    padding: 40px 20px;
    gap: 20px;
    width: 100%;
    
}
.confirm-text{
    font-family: Work Sans;
    font-size: 48px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.32px;
}
.bottom-image{
    margin-top: 30px;
    width: 240px;
    height:240px
}

.lock-container {
    position: relative;
}
.hint-text{
    font-family: Work Sans;
    font-size: 28px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.32px;
    color:rgba(129, 129, 129, 1);
    text-align: center;
}
.pinCode{
    width:100%
}
.submit{
    height: 50px;
    font-family: Work Sans; 
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #020202;
    background-color: #FCDBCC;
    border-radius: 10px;
    text-transform: capitalize;
    width: 25%;
    border:transparent;
}
.resend{
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #818181;
    text-decoration: underline;
    border: none;
    background-color: transparent;


} 

@media screen and (max-width: 767px) {
    .verification-container{
        padding-top: 115px;
    }
    
    .confirm-text{
        font-size: 28px;
        font-weight: 500;
        line-height: 24px;
    }
    .bottom-image{
        margin-top: 15px;
        width: 91px;
        height: 91px;
    }
    .hint-text{
        font-size: 16px;
        line-height: 18px;
    }
    .submit{
        width:100%
    }
    .resend{
        font-size: 12px;}


}

