.mini {
  min-height: 600px;
}

.button-container-payment .btn:active {
  background-color: white;
  border: 1px solid rgba(252, 219, 204, 1);
  /* Set border color when button is clicked */
}

.first-box-payment-page {
  height: 328px;
  width: 100%;
  border: 1px solid rgba(252, 219, 204, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 4% 4%;
}

.first-box-title-payment-page {
  font-size: 18px;
  font-family: Work Sans;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 16px;
  color: rgba(129, 129, 129, 0.67);
}

.first-box-email-payment-page {
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 16px;
  color: rgba(129, 129, 129, 0.67);
  margin-top: 15px;
}

.black-line-payment-page {
  width: 100%;
  height: 1px;
  background-color: rgba(170, 170, 170, 1);
}

.promocode-text-box-payment-page {
  display: flex;
  width: 80%;
  background-color: transparent;
  border: none;
}

input:focus {
  outline: none;
}

.promocode-button-payment-page {
  background-color: rgba(252, 219, 204, 1);
  height: 48px;
  width: 13%;
  border-radius: 10px;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loader-overlay-payment-page {
  position: absolute;
  /* Position relative to the parent div */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Centering trick */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.black-line-payment-page-box-2 {
  width: 100%;
  height: 1px;
  background-color: rgba(170, 170, 170, 1);
}

.promocode-container-payment-page {
  display: flex;
  flex-direction: row;
  gap: 2%;
  margin-top: 20px;
}

.promocode-title-payment-page {
  font-size: 18px;
  font-family: Work Sans;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 16px;
  color: rgba(129, 129, 129, 0.67);
  margin-top: 30px;
}

.promocode-text-box-cont-payment-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background-color: rgba(252, 219, 204, 1);
  height: 48px;
  width: 85%;
  border-radius: 10px;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  border: none;
  padding-left: 4%;
  padding-right: 4%;
  outline: none;
}

.promocode-error-message {
  font-family: Work Sans;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.32px;
  color: rgba(243, 39, 64, 1);
  margin-left: 2%;
}

.switch-toogle-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 1%;
}

.switch-toogle {
  display: flex;
  flex-direction: row;
  margin-left: 2%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.switch-toogle-text {
  margin-left: 3%;
  margin-right: 3%;
  font-family: Work Sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: rgba(35, 31, 32, 1);
}

.payment-title-payment-page {
  font-size: 18px;
  font-family: Work Sans;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 16px;
  color: rgba(129, 129, 129, 0.67);
  margin-top: 40px;
  margin-bottom: 20px;
}

.second-box-payment-page {
  width: 100%;
  border: 1px solid rgba(252, 219, 204, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3% 4%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.radio-options {
  display: flex;
  flex-direction: column;
}

.container-payment-page {
  display: flex;
  height: 25px;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 35px; /* Added for RTL support */
  direction: ltr; /* Default direction */
}
.container-payment-page.rtl {
  direction: rtl; /* RTL direction for Arabic */
  padding-left: 0;
  padding-right: 35px; /* Adjust padding for RTL */
}

.container-payment-page input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  padding: 0;
}

/* Create a custom radio button */
.checkmark {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: rgb(252, 219, 204);
  border-radius: 50%;
  left: 0;
}

.container-payment-page.rtl .checkmark {
  left: auto;
  right: 0; /* For RTL */
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-payment-page input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-payment-page .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.radio-options {
  font-size: 14px;
  font-family: Work Sans;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 16px;
  color: rgba(0, 0, 0, 1);
}

.cardProviders {
  display: flex;
  width: 20%;
  height: 25px;
}

.container-payment-page-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subtotal-container-pc {
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .mobile-payment-page {
    display: none;
  }

  .pc-payment-page {
    width: 100%;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    margin-top: 177px;
    padding: 5% 5%;
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
  }

  .Left-Align-payment {
    width: 50%;
    height: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .Right-Align-payment {
    width: 50%;
    height: 100%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .single-product-coloum-payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3%;
  }

  .single-coloum-payment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5%;
    width: 100%;
  }
  .single-coloum-payment.arabic {
    margin-left: 0;
    margin-right: 5%;
  }
  .mirror {
    transform: scaleX(-1); /* Mirrors the image horizontally */
  }

  .product-name-payment {
    font-size: 16px;
    font-family: Work Sans;
    font-weight: 600;
    letter-spacing: -0.32px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .product-price-payment {
    font-size: 18px;
    font-family: Work Sans;
    line-height: 16px;
    font-weight: 500;
  }

  .product-counter-payment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-remove-payment {
    font-size: 14px;
    font-family: Work Sans;
    font-weight: 400;
    color: rgba(129, 129, 129, 1);
    text-decoration: underline;
    right: 0;
    display: flex;
    align-items: flex-end;
  }

  .single-product-payment {
    display: flex;
    flex-direction: row;
    height: 130px;
    width: 99%;
    margin-bottom: 3%;
  }

  .Return-Order-Info-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .arrow {
    transform: scaleX(-1);
  }
  .arrow.arabic {
    transform: scale(1);
  }

  .return-order-info {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgba(129, 129, 129, 1);
    text-decoration: underline;
    margin-left: 1%;
  }
}

@media (max-width: 767px) {
  .mirror {
    transform: scaleX(-1);
  }
  .pc-payment-page {
    display: none;
  }

  .mobile-payment-page {
    width: 100%;

    background-color: rgb(255, 255, 255);
    padding-top: 115px;
  }

  .payment-page-container {
    /* padding-left: 5%;
        padding-right: 5%; */
    height: 100%;
  }

  .payment-page-container-2 {
    padding-left: 5%;
    padding-right: 5%;
  }

  .row-drop3 {
    height: 6px;
    width: 100%;
    display: flex;
    flex-direction: row;
    z-index: 7;
  }

  .red3 {
    background-color: rgb(252, 219, 204);
    width: 94%;
  }

  .light-gray3 {
    width: 6%;
  }

  .ordermap3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 10px;
  }

  .Information3 {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgba(129, 129, 129, 1);
  }

  .shipping3 {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgba(129, 129, 129, 1);
  }

  .payment3 {
    font-family: Work Sans;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgba(129, 129, 129, 1);
  }

  .second-box-payment-page {
    justify-content: space-around;
    padding: 4% 4%;
  }

  .cardNumber-text-box-payment-page {
    display: flex;
    background-color: rgba(252, 219, 204, 0.3);
    height: 48px;
    width: 100%;
    border-radius: 10px;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    border: none;
    padding-left: 4%;
    outline: none;
  }

  .cardNumber-label-payment-page {
    margin-bottom: 2px;
    margin-left: 1%;
    font-size: 12px;
    font-family: Work Sans;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .cardNumber-Container-payment-page {
    margin-top: 10px;
  }

  .pay-Now-button {
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .subtotal-container-payment {
    background-color: rgb(255, 244, 240);
    height: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5% 3%;
  }

  .subtotal-container-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .subtotal-title-row {
    font-size: 16px;
    font-weight: 300;
    font-family: Work Sans;
    letter-spacing: -0.32px;
    line-height: 16px;
  }

  .subtotal-value-row {
    font-size: 16px;
    font-weight: 500;
    font-family: Work Sans;
    letter-spacing: -0.32px;
    line-height: 16px;
  }
}
