.perview-product-mobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .perview-product-mobile {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 135px;
  }

  .path-perview {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    display: block;
    font-family: "Work Sans";
  }

  .perview-product-hearts-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .perview-product-num_reviews {
    color: #020202;
    font-family: "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    font-size: 12px;
  }

  .perview-product-name {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.326px;
    color: #231f20;
    margin-top: 10px;
  }

  .perview-product-image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    margin-top: 50px;
    max-width: 100%;
  }

  .perview-product-promotional {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.32px;
    color: #000000;
    margin-top: 40px;
  }
  .perview-product-add-to-cart {
    border-radius: 10px;
    color: #020202;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    outline: none;
    height: 48px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .perview-product-more-details {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-decoration: underline;
  }
  .single-product-add-to-cart:hover {
    background: #eddcb8;
    color: #231f20;
  }
  .perview-pair-it-with {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-top: 50px;
  }

  .perview-product-pair-hearts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .perview-pair-it-with-name {
    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-top: 10px;
  }
}
