.accessories-container{
    min-height: 200px;
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: DM Serif Display;
    font-size: 40px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.32px;
    text-align: center;
    background-color: rgb(255, 244, 240);
}
.accessories-italic{
    margin-top: 5px;
    font-style: italic;
}
@media (max-width: 767px) {
    .accessories-container{
        line-height: 24px;
        min-height: 200px;
    }
    .accessories-title{
        font-size: 27px;
    }
    .accessories-italic{
        font-size: 24px;
    }
}