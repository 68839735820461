.sign-up {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  padding: 40px 20px;
  gap: 30px;
  font-family: DM Serif Display;
  font-size: 27px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 197px;
}
.picker {
  padding-right: 15px;
}

.sign-up-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 30%;
  position: relative;
}
input:-webkit-autofill {
  -webkit-background-clip: text !important; /* Clip to text */
  background-color: transparent !important; /* Transparent background */
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-field {
  border-radius: 10px;
  background-color: rgba(252, 219, 204, 0.3);
  border: none;
  outline: none;
}
.input-container label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

.input-field input {
  width: 100%;
  height: 48px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0.5rem;
  border: 2px solid transparent;
  border-radius: 3px;
  transition: box-shadow 0.2s ease-in;
  color: inherit;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropdown {
  width: 30%;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  height: 42px;
  background-color: transparent !important;
  border-color: rgb(254, 243, 239);
  color: #818181;
  border-radius: 10px;
}
.password-instructions {
  color: #818181;
  text-align: start;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  padding-left: 4%;
  padding-right: 4%;

  font-family: Work Sans;
}
.receive-emails {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #231f20;
  display: flex;
  align-items: flex-start; /* Align items at the start */
  gap: 10px;
  margin-left: 2%;
}

.receive-emails span {
  padding: 0px;
}

.submit {
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #020202;
  background-color: #fcdbcc;
  border-radius: 10px;
  text-transform: capitalize;
}
.how-you-know-container {
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 2%;
}
.how-you-know-question {
  color: #818181;
  font-family: Work Sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.32px;
  margin-bottom: 15px;
}
.how-you-know-options {
  display: flex;
  flex-direction: column;
  color: rgba(129, 129, 129, 1);
  font-family: Work Sans;
  font-weight: 300;
  font-size: 14px;
  line-height: 10px;
  letter-spacing: -0.32px;
  gap: 15px;
}
.how-you-know-options span {
  padding: 0px;
  margin-right: 2%;
}
.input-sign-up {
  border-color: red;
}

.input-row {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.input:focus,
.input:focus-within {
  outline: none;
  box-shadow: 0 0 10px;
}

.input-group.invalid {
  color: red;
}

.input-group.valid {
  color: forestgreen;
}

.input-group.invalid .input {
  border: 2px solid red;
}

.input-group.invalid .input:focus,
.input-group.invalid .input:focus-within {
  box-shadow: 0 0 10px lightcoral;
}

.input-group.valid .input:focus,
.input-group.valid .input:focus-within {
  box-shadow: 0 0 10px lightgreen;
}

.input-group.valid .input {
  border: 2px solid forestgreen;
}

.input-error {
  padding-top: 5px;
  padding-left: 1%;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.32px;
}

.input__icon {
  right: 0.5rem;
  top: 2.1rem;
}

.btn {
  color: black;
  background-color: rgba(252, 219, 204, 1);
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  border-radius: 5px;
  font-family: Work Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  height: 48px;
}

.btn:hover {
  color: rgba(252, 219, 204, 1);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
}

.btn.disabled,
.btn.disabled:hover {
  background-color: rgba(196, 192, 192, 1);
  border: 1px solid rgba(196, 192, 192, 1);
  color: white;
  cursor: not-allowed;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.PuffLoader {
  z-index: 10;
}
.error-message {
  font-family: Work Sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: red;
}

.phone-input-wrapper input {
  font-family: "Work Sans" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: -0.32px !important;
}

@media screen and (max-width: 767px) {
  .sign-up {
    padding-top: 135px;
  }
  .sign-up-info {
    width: 100%;
  }
}
