.mobile-view-login {
  display: none;
}

.web-view-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px;
  margin-top: 277px;
  margin-bottom: 250px;
}

.login-container .welcome-text {
  font-family: DM Serif Display;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.32px;
  text-align: center;
  line-height: 48px;
  font-size: 48px;
  margin-bottom: 10%;
}

.login-container .input-container {
  margin-top: 4%;
}

.login-container .input-field input {
  height: 61px;
}

.login-container .input-container svg {
  display: none;
}

.forgot-password {
  font-weight: 400;
  line-height: 16px;
  color: #818181;
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  margin-top: 4%;
}
.submit-login {
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #020202;
  background-color: #fcdbcc;
  border-radius: 10px;
  text-transform: capitalize;
  border: none;
  width: 100%;
  height: 61px;
  font-size: 24px;
  margin-top: 4%;
}

.no-account {
  font-family: "Work Sans";
  font-weight: 400;
  line-height: 16px;
  color: #b0b0b0;
  flex-direction: row;
  text-align: center;
  font-size: 18px;
  margin-top: 4%;
}

.no-account .underline {
  text-decoration: underline;
}

.error-message-login {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  margin-top: 4%;
  color: red;
}

.blurry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(254, 244, 240, 0.85);
  z-index: 1100;
}

.forget-password-pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1101;
  text-align: center;
  width: 555px;
  height: 352px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;

  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.32px;
  border-radius: 10px;
}

.forget-password-pop-up .input-field input {
  height: 66px;
}

.forget-password-pop-up .input-container svg {
  display: none;
}

.forget-password-pop-up .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

.forget-password-pop-up .close-btn:hover {
  color: red;
}

@media screen and (max-width: 767px) {
  .mobile-view-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 135px;
  }

  .web-view-login {
    display: none;
  }
  .login-container {
    width: 100%;
  }

  .login-container .welcome-text {
    font-size: 27px;
    margin-bottom: 7%;
  }

  .login-container .input-field input {
    height: 50px;
  }

  .forgot-password {
    font-size: 14px;
  }

  .submit-login {
    height: 50px;
    font-size: 16px;
  }

  .no-account {
    font-size: 14px;
  }

  .error-message-login {
    font-size: 14px;
  }

  .forget-password-pop-up {
    padding: 20px;
    width: 80%;
    height: 350px;
    gap: 10px;
    font-size: 16px;
    line-height: 20px;
  }

  .forget-password-pop-up .input-field input {
    height: 48px;
  }

  .forget-password-pop-up .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
  }
}
