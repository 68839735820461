
@media (min-width: 768px) {
    .orderFailed-container{
        margin-top: 177px;
        display: flex;
        justify-content: center;
        
    }
    .orderFailed-body{
        display: flex;
        margin-top: 50px;
        flex-direction: column;
    }
    .order-Failed-title{
        margin-top: 20px;
        font-size: 40px;
        font-family: Work Sans;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.32px;
        color:rgba(252, 219, 204, 1);
        display: flex;
        justify-content: center;
    }
    .order-Failed-text-body{
        margin-top: 20px;
        font-size: 20px;
        font-family: Work Sans;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        display: flex;
        justify-content: center;
        text-align: center;
        width:433px;
    }
    .continue-shopping-button-OF{
        margin-top: 20px;
        width:424px;
        height:71px;
        background-color: rgb(253, 220, 205);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Work Sans;
        font-size: 24px;
        font-weight: 500;
        line-height: 16px;
        color: rgba(2, 2, 2, 1);
    }
   
    .orderFailed-corect-sign {
        color: rgb(252, 219, 204);
        font-size: 452px;
      }
    
    }
    @media (max-width: 767px) {
      
        .orderFailed-container{
            padding-top: 115px;
            display: flex;
            justify-content: center;
            
        }
        .orderFailed-body{
          
            margin-top: 50px;
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .order-Failed-title{
            margin-top: 20px;
            font-size: 28px;
            font-family: Work Sans;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.32px;
            color:rgba(252, 219, 204, 1);
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .order-Failed-text-body{
            margin-top: 20px;
            font-size: 16px;
            font-family: Work Sans;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: -0.32px;
            display: flex;
            justify-content: center;
            text-align: center;
            width:328px;
        }
        .continue-shopping-button-OF{
            margin-top: 20px;
            width:353px;
            height:48px;
            background-color: rgb(253, 220, 205);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Work Sans;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            color: rgba(2, 2, 2, 1);
        }
       
     
        .orderFailed-corect-sign {
            color: rgb(252, 219, 204);
            font-size: 274px;
            
          
          }
    
    }