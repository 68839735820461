/* In fonts.css or home.css */
/* @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
.home-text-italic {
    font-style: italic;
}
.home-text-italic.arabic{
    font-style: normal;
}

/* Styles for iPad - Width between 768px and 1023px */
@media (min-width: 768px) and (max-width: 1023px) {
   
        .mobile-home-container {
            display: flex;
            flex-direction: column;
            background-color: rgb(255, 244, 240);
            align-items: center;
            justify-content: center;
            width: 100%;
            /* Ensure the container fills the available width */
            padding-top: 115px;
        }
    
        a {
            text-decoration: none;
            color: inherit;
        }
    
        .hearts-container {
            margin-bottom: 10px;
        }
    
        video {
            width: 100%;
            height: 500px;
            object-fit: cover;
        }
    
        .mobile-hide {
            display: none;
        }
    
        body {
            overflow-x: hidden;
            /* Prevent horizontal scrolling */
        }
    
        .animation-Video {
            width: 100%;
    
        }
    
        .home-text1 {
            margin-top: 30px;
            font-family: 'DM Serif Display';
            font-weight: 400;
            font-size: 28px;
            line-height: 27px;
            letter-spacing: -0.32px;
        }
        .home-text1.arabic{
            font-family: 'Cairo';
            font-size: 24px;
            line-height: 28px;
            font-weight: 500;
        } 
        .best-selling-mobile-text {
            margin-top: 60px;
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 28px;
            line-height: 18px;
            letter-spacing: -0.32px;
            color: rgba(227, 166, 137, 1);
        }
        .best-selling-mobile-text.arabic{
            font-family: "Cairo";
            font-size: 24px;
        }
    
        .best-selling-swiper-container {
            margin-top: 30px;
            margin-bottom: 20px;
            width: 90%;
            height: 560px;
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            border-width: 1px;
            border-color: rgba(252, 219, 204, 1);
        }
    
        .swiper {
            width: 100%;
            height: 100%;
            border-radius: 10px;
    
        }
    
        .product-selling {
    
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .productImg {
            width: 90%;
            margin-bottom: 10px;
            border-radius: 10px;
        }
    
        .hearts-container {
            display: flex;
            flex-direction: row;
        }
    
        .product-name-home-mobile {
            font-family: 'DM Serif Display', serif;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: -0.32px;
            text-align: center;
            color: #231F20;
            margin-top: 0px;
            height: 35px;
        }
        .product-name-home-mobile.arabic{
            font-family: "Cairo";
            font-weight: 500;
        }
    
        .add-to-cart-button {
            border-radius: 10px;
            color: #020202;
            font-family: "Work Sans", sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            cursor: pointer;
            border: none;
            outline: none;
            width: 80%;
            height: 48px;
        }
        .add-to-cart-button.arabic{
            font-family: "Cairo";
            font-weight: 700;
            font-size: 12px;
            line-height: 10px;
            letter-spacing: -0.32px;
        }
    
    
    
        .ShopAll-Button {
            width: 40%;
            height: 48px;
            border-radius: 10px;
            color: #020202;
            text-align: center;
            font-family: 'Work Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
            padding: 10px 20px;
            border: 1px solid rgb(254, 231, 222);
            background-color: transparent;
            outline: none;
            cursor: pointer;
            margin-top: 0;
            display: flex;
            /* Add flexbox */
            justify-content: center;
            /* Center horizontally */
            align-items: center;
        }
        .ShopAll-Button.arabic{
            font-family: "Cairo";
            font-weight: 700;
            font-size: 12px;
            line-height: 10px;
        }
    
        .home-text2 {
            width: 90%;
            height: 60px;
            font-family: 'DM Serif Display', serif;
            font-weight: 400;
            font-style: normal;
            font-size: 28px;
            line-height: 28px;
            letter-spacing: -0.32px;
            text-align: center;
            color: #231F20;
            margin-top: 50px;
        }
        .home-text2.arabic{
            font-family: "Cairo";
            font-weight: 500;
            font-size: 24px;
        }
    
        .home-text2-italic {
            font-style: italic;
        }
        .home-text2-italic.arabic{
            font-style: normal;
        }
    
        .home-image2 {
            width: 100%;
            margin-top: 10px;
        }
    
        .icons-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    
        .iconGroup {
            display: flex;
            margin-top: 40px;
            margin-bottom: 40px;
            padding-left: 7%;
            padding-right: 7%;
            width: 100%;
        }
    
        .icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20%;
            height: 77.23px;
    
        }
    
        .img {
            width: 100%;
            height: 58.01px;
        }
    
        .icon-text-mobile {
            color: #E3A689;
            font-family: 'Work Sans';
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 8px;
            letter-spacing: -0.32px;
            text-align: center;
            width: 100%;
            height: 20px;
        }
    
        .quiz-container-mobile {
            position: relative;
            margin-bottom: 40px;
            height: 250px;
            width: 100%;
            background-color: rgb(229, 248, 247);
            display: flex;
            /* Add flex display */
            /* justify-content: space-between;  */
            align-items: center;
            /* Center items vertically */
        }
    
        .quiz-image-mobile {
            width: 50%;
        }
    
        .quizFrame-text-mobile {
            width: 50%;
            padding-left: 5%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .quizFrame-text-mobile.arabic{
            padding-left: 0;
            padding-right: 5%;
        }
    
        .quiz-text-mobile {
            margin-top: 5%;
            width: 85%;
            height: 89px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.42px;
            margin-top: 17%;
            font-weight: 300;
            font-family: 'Work Sans';
        }
        .quiz-text-mobile.arabic{
            font-family: "Cairo";
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: -0.32px;
        }
    
        .quiz-button-mobile {
            border: none;
            cursor: pointer;
            transition: background-color 0.3s ease;
            border-radius: 10px;
            background: linear-gradient(90deg, #AAD6D4 120.29%, rgba(88, 147, 212, 0.00) 138.35%);
            text-align: center;
            font-family: 'Work Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            width: 75%;
            height: 40px;
            flex-shrink: 0;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .quiz-button-mobile.arabic{
            font-family: "Cairo";
            font-weight: 700;
            font-size: 12px;
            line-height: 10px;
            letter-spacing: -0.32px;
        }
    
    
        .earn-points-text-cont {
            position: absolute;
            padding: 7% 3%;
            z-index: 1;
            font-family: Work Sans;
            color: rgba(35, 31, 32, 1);
            display: flex;
            flex-direction: column;
            width: 47%;
            height: 100%;
    
        }
    
        .earn-points-title {
            font-weight: 500;
            font-size: 20px;
            line-height: 12px;
            margin-bottom: 15px;
        }
        .earn-points-title.arabic{
            font-family: "Cairo";
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: -0.32px;
        }
       
    
        .earn-point-cont {
            margin-top: 50px;
            position: relative;
            width: 100%;
            margin-bottom: 100px;
        }
    
        .earn-points-subtitle {
            font-weight: 500;
            font-size: 16px;
            margin-top: 10px;
            line-height: 16px;
        }
        .earn-points-subtitle.arabic{
            font-family: "Cairo";
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.32px;
        }
    
        .earn-points-text {
            font-weight: 300;
            font-size: 16px;
            margin-top: 10px;
            line-height: 16px;
        }
        .earn-points-text.arabic{
            font-family: "Cairo";
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: -0.32px;
            margin-top: 0;
        }
    
        .earn-now-button {
            width: 75%;
            height: 40px;
            border-radius: 10px;
            color: #020202;
            text-align: center;
            font-family: 'Work Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            display: flex;
            border: 1px solid rgb(0, 0, 0);
            background-color: transparent;
            outline: none;
            cursor: pointer;
            margin-top: 35px;
        }
        .earn-now-button.arabic{
            font-family: "Cairo";
            font-weight: 700;
            font-size: 12px;
            line-height: 10px;
            letter-spacing: -0.32px;
        }
        .earnPoints-Img {
            width: 100%;
        }
    
        .earn-point-container-mobile {
            width: 90%;
            height: 200px;
            background-color: rgb(252, 219, 204);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
            justify-content: center;
        }
    
        .earn-point-mobile {
            font-family: 'Work Sans';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-transform: uppercase;
            margin-top: 30px;
        }
    
        .good-deal-mobile {
            font-family: 'Work Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin-top: 20px;
        }
    
        .VIP-mobile {
            font-family: 'Work Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            margin-top: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
        }
    
        .VIP2-mobile {
            font-family: 'Work Sans';
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
        }
    
        .earn-now-button-mobile {
            width: 30%;
            height: 40px;
            border-radius: 10px;
            color: #020202;
            text-align: center;
            font-family: 'Work Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            padding: 15px;
            display: flex;
            border: 1px solid rgb(0, 0, 0);
            background-color: transparent;
            outline: none;
            cursor: pointer;
            margin-top: 18px;
            margin-bottom: 19px;
        }
        .test-product-div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    
        .product-image-div{
            height: 65%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    
    
}

@media (min-width: 1024px) {
    .home-container {
        background-color: rgba(252, 219, 204, 0.30);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 177px;
        background-color: rgb(255, 244, 240);
    }
 

    .earn-points-text-cont {
        position: absolute;
        top: 40%;
        margin-left: 5%;
        z-index: 1;
        font-family: Work Sans;
        font-size: 42px;
        line-height: 40px;
        color: rgba(35, 31, 32, 1);
        display: flex;
        flex-direction: column;
        width: 47%;
    }
    .earn-points-text-cont.arabic{
        margin-left: 0;
        margin-right: 5%;
    }

    .earn-points-title {
        font-weight: 600;
    }
    .earn-points-title.arabic{
        font-family: "Cairo";
        font-weight: 600;
        font-size: 48px;
    }

    .earn-point-cont {
        margin-top: 100px;
        position: relative;
        width: 100%;
        margin-bottom: 100px;
    }

    .earn-points-subtitle {
        font-weight: 500;
        margin-top: 30px;
    }
    .earn-points-subtitle.arabic{
        font-family: "Cairo";
        font-size: 35px;
        font-weight: 600;
        line-height: 40px;
    }
    .earn-points-text {
        font-weight: 300;
        margin-top: 10px;
    }
    .earn-points-text.arabic{
        font-family: "Cairo";
        font-size: 35px;
        font-weight: 600;
        line-height: 40px;
        margin-top: 0;
    }
    .earn-now-button {
        width: 25%;
        height: 60px;
        border-radius: 10px;
        color: #020202;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        padding: 15px;
        display: flex;
        border: 1px solid rgb(0, 0, 0);
        background-color: transparent;
        outline: none;
        cursor: pointer;
        margin-top: 40px;
    }
    .earn-now-button.arabic{
        font-family: "Cairo";
        font-weight: 600;
        font-size: 18px;
    }


    body {
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .headline-row-home {
        display: flex;
        color: #231F20;
        font-family: "DM Serif Display";
        font-size: 48px;
        font-weight: 400;
        text-align: center;
        align-items: center;
        justify-content: center;
        letter-spacing: -0.32px;
        width: 100%;
        height: 113px;
        flex-shrink: 0;
        padding-top: 21PX;
        font-style: normal;
        line-height: 48px;
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 20px;
    }
    .headline-row-home.arabic {
        font-family: 'Cairo' !important;
        font-weight: 600;
        
      }

    .headline-row-home-italic {
        font-style: italic;
    }
    .headline-row-home-italic.arabic{
        font-style: normal;
    }

    .animation-Image {

        display: flex;
        width: 75%;
        height: 573;
        padding-bottom: 40px;
    }

    .video-container {
        width: 100%;
        /* Adjust width as needed */
        height: 0;
        padding-bottom: 52.63%;
        /* 1.9:1 aspect ratio (1 / 1.9 * 100%) */
        position: relative;
        overflow: hidden;
    }

    .animation-Video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Fill container, preserving aspect ratio */
    }

    .shop-Now {
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        background: linear-gradient(90deg, #AAD6D4 120.29%, rgba(88, 147, 212, 0.00) 138.35%);
        text-align: center;
        font-family: 'Work Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding: 27px 40px;
        width: 15%;
        height: 71px;
        flex-shrink: 0;
        margin-bottom: 30px;
    }

    .shop-Now:hover {
        background-color: darkred;

    }

    .best-Selling-Container {
        width: 100%;
        display: flex;
        margin-top: 100px;
        flex-direction: column;
    }

    .best-Selling {
        color: rgb(227, 166, 137);
        font-family: "Work Sans";
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.32px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-left: 2%;
        margin-bottom: 40px;
    }
    .best-Selling.arabic {
        font-family: 'Cairo' !important;
        font-weight: 600;
        font-size: 35px;
        line-height: 40px;
        margin-right: 2%;
    }

    .products-list {
        display: flex;
        flex-direction: row;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: space-between;
    }

    .single-product {
        width: 30%;
        height: 730px;
    }

    .single-product-image-container {
        width: 100%;
        height: 70%;
        background-color: white;
        border-radius: 10px;
        border-width: 1px;
        border-color: rgba(252, 219, 204, 1);
        border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

    }

    .single-product-image {

        width: 90%;
        flex-shrink: 0;
        border-radius: 10px;

    }


    .products {
        display: flex;
        flex-direction: row;
        gap: 10%;
        margin-left: 1%;
        margin-right: 1%;
    }

    .product-Item {
        width: 30%;
    }


    .hearts-container {
        display: flex;
        flex-direction: row;
        color: rgb(252, 219, 204);
        justify-content: center;
        margin-top: 7px;
        gap: 3px;
    }

    .product-image {
        width: 404.388px;
        height: 480px;
        flex-shrink: 0;
        border-radius: 10px;
        background: lightgray 50% / cover no-repeat;
    }

    .product-name-home-pc {
        display: flex;
        height: 6%;
        flex-direction: column;
        justify-content: flex-start;
        flex-shrink: 0;
        color: #231F20;
        text-align: center;
        font-family: "DM Serif Display";
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.32px;
        margin-top: 10px;
    }
    .product-name-home-pc.arabic{
        font-family: "Cairo";
        
    }

    .add-to-cart-button {
        width: 100%;
        height: 71px;
        border-radius: 10px;
        background: linear-gradient(90deg, #fddccd 120.29%, rgba(88, 147, 212, 0.00) 138.35%);
        color: #020202;
        text-align: center;
        font-family: "Work Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding: 10px 20px;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
    }
    .add-to-cart {
        border-radius: 10px;
        color: #020202;
        font-family: "Work Sans", sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        padding: 10px 20px;
        cursor: pointer;
        border: none;
        outline: none;
        color: #020202;
        width: 100%;
        height: 71px;
      }
      .add-to-cart.arabic{
        font-family: "Cairo";
        font-size: 18px;
        font-weight: 600;
      }

    a {
        text-decoration: none;
        color: inherit;
    }

    .ShopAll-Button {
        height: 48px;
        border-radius: 10px;
        color: #020202;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding: 10px 20px;
        /* margin-top: 45px; */
        margin-bottom: 4%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid rgb(254, 231, 222);
        background-color: transparent;
        outline: none;
        cursor: pointer;
        /* margin-top: 100px; */
    }
    .ShopAll-Button.arabic{
        font-family: 'Cairo' !important;
        font-weight: 600;
        font-size: 20px;
        line-height: 18px;
    }

    /* Style button on hover */
    .ShopAll-Button:hover {
        background-color: rgba(254, 231, 222, 0.2);
        /* Add some opacity on hover */
    }

    .iconGroupPC {
        display: flex;
        margin-top: 40px;
        margin-bottom: 60px;
        padding-left: 3%;
        padding-right: 3%;
        width: 50%;
    }

    .icons-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
        margin-top: 40px;
        gap: 3%;
    }

    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3%;
    }

    .icon-text {
        color: #E3A689;
        font-family: 'Work Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.32px;
        text-transform: capitalize;
        text-align: center;

    }

    .quiz-background {
        width: 100%;
        height: 730px;
        background: #E5F8F7;
        flex-shrink: 0;
        padding: 0px;
        margin-bottom: 100px;
        display: flex;
        align-items: flex-start;
    }

    .quiz-image {
        width: 60%;
        /* height: 688px; */
        /* position: absolute; 
        right: 0 */
    }

    .quizFrame-text {
        display: flex;
        flex-direction: column;
        gap: 5%;
        margin-left: 5%;
        width: 40%;
    }
    .quizFrame-text.arabic{
        margin-left: 0;
        margin-right: 10%;
    }

    .quiz-text {
        width: 65%;
        height: 302px;
        font-size: 52px;
        line-height: 52px;
        letter-spacing: -0.42px;
        margin-top: 35%;
        font-weight: 300;
        font-family: 'Work Sans';
    }
    .quiz-text.arabic{
        font-family: "Cairo";
        font-size: 46px;
    }
    .take-quiz{
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        background: linear-gradient(90deg, #AAD6D4 120.29%, rgba(88, 147, 212, 0.00) 138.35%);
        text-align: center;
        font-family: 'Work Sans';
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        width: 35%;
        height: 71px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .take-quiz{
        font-family: "Cairo";
        font-size: 18px;
        font-weight: 600;
    }

    .mobile-home-container {
        display: none;
    }
}

/* Add this CSS to your stylesheet */
@media (max-width: 767px) {
    .mobile-home-container {
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 244, 240);
        align-items: center;
        justify-content: center;
        width: 100%;
        /* Ensure the container fills the available width */
        padding-top: 115px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .hearts-container {
        margin-bottom: 10px;
    }

    video {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    .mobile-hide {
        display: none;
    }

    body {
        overflow-x: hidden;
        /* Prevent horizontal scrolling */
    }

    .animation-Video {
        width: 100%;

    }

    .home-text1 {
        margin-top: 30px;
        font-family: 'DM Serif Display';
        font-weight: 400;
        font-size: 28px;
        line-height: 27px;
        letter-spacing: -0.32px;
    }
    .home-text1.arabic{
        font-family: 'Cairo';
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
    } 
    .best-selling-mobile-text {
        margin-top: 60px;
        font-family: 'Work Sans';
        font-weight: 500;
        font-size: 28px;
        line-height: 18px;
        letter-spacing: -0.32px;
        color: rgba(227, 166, 137, 1);
    }
    .best-selling-mobile-text.arabic{
        font-family: "Cairo";
        font-size: 24px;
    }

    .best-selling-swiper-container {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 90%;
        height: 560px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        border-width: 1px;
        border-color: rgba(252, 219, 204, 1);
    }

    .swiper {
        width: 100%;
        height: 100%;
        border-radius: 10px;

    }

    .product-selling {

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .productImg {
        width: 90%;
        margin-bottom: 10px;
        border-radius: 10px;
    }

    .hearts-container {
        display: flex;
        flex-direction: row;
    }

    .product-name-home-mobile {
        font-family: 'DM Serif Display', serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.32px;
        text-align: center;
        color: #231F20;
        margin-top: 0px;
        height: 35px;
    }
    .product-name-home-mobile.arabic{
        font-family: "Cairo";
        font-weight: 500;
    }

    .add-to-cart-button {
        border-radius: 10px;
        color: #020202;
        font-family: "Work Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        cursor: pointer;
        border: none;
        outline: none;
        width: 80%;
        height: 48px;
    }
    .add-to-cart-button.arabic{
        font-family: "Cairo";
        font-weight: 700;
        font-size: 12px;
        line-height: 10px;
        letter-spacing: -0.32px;
    }



    .ShopAll-Button {
        width: 40%;
        height: 48px;
        border-radius: 10px;
        color: #020202;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        padding: 10px 20px;
        border: 1px solid rgb(254, 231, 222);
        background-color: transparent;
        outline: none;
        cursor: pointer;
        margin-top: 0;
        display: flex;
        /* Add flexbox */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
    }
    .ShopAll-Button.arabic{
        font-family: "Cairo";
        font-weight: 700;
        font-size: 12px;
        line-height: 10px;
    }

    .home-text2 {
        width: 90%;
        height: 60px;
        font-family: 'DM Serif Display', serif;
        font-weight: 400;
        font-style: normal;
        font-size: 28px;
        line-height: 28px;
        letter-spacing: -0.32px;
        text-align: center;
        color: #231F20;
        margin-top: 50px;
    }
    .home-text2.arabic{
        font-family: "Cairo";
        font-weight: 500;
        font-size: 24px;
    }

    .home-text2-italic {
        font-style: italic;
    }
    .home-text2-italic.arabic{
        font-style: normal;
    }

    .home-image2 {
        width: 100%;
        margin-top: 10px;
    }

    .icons-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .iconGroup {
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
        padding-left: 7%;
        padding-right: 7%;
        width: 100%;
    }

    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 77.23px;

    }

    .img {
        width: 100%;
        height: 58.01px;
    }

    .icon-text-mobile {
        color: #E3A689;
        font-family: 'Work Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 8px;
        letter-spacing: -0.32px;
        text-align: center;
        width: 100%;
        height: 20px;
    }

    .quiz-container-mobile {
        position: relative;
        margin-bottom: 40px;
        height: 250px;
        width: 100%;
        background-color: rgb(229, 248, 247);
        display: flex;
        /* Add flex display */
        /* justify-content: space-between;  */
        align-items: center;
        /* Center items vertically */
    }

    .quiz-image-mobile {
        width: 50%;
    }

    .quizFrame-text-mobile {
        width: 50%;
        padding-left: 5%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .quizFrame-text-mobile.arabic{
        padding-left: 0;
        padding-right: 5%;
    }

    .quiz-text-mobile {
        margin-top: 5%;
        width: 85%;
        height: 89px;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.42px;
        margin-top: 17%;
        font-weight: 300;
        font-family: 'Work Sans';
    }
    .quiz-text-mobile.arabic{
        font-family: "Cairo";
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.32px;
    }

    .quiz-button-mobile {
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        background: linear-gradient(90deg, #AAD6D4 120.29%, rgba(88, 147, 212, 0.00) 138.35%);
        text-align: center;
        font-family: 'Work Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        width: 75%;
        height: 40px;
        flex-shrink: 0;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .quiz-button-mobile.arabic{
        font-family: "Cairo";
        font-weight: 700;
        font-size: 12px;
        line-height: 10px;
        letter-spacing: -0.32px;
    }


    .earn-points-text-cont {
        position: absolute;
        padding: 7% 3%;
        z-index: 1;
        font-family: Work Sans;
        color: rgba(35, 31, 32, 1);
        display: flex;
        flex-direction: column;
        width: 47%;
        height: 100%;

    }

    .earn-points-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 12px;
        margin-bottom: 15px;
    }
    .earn-points-title.arabic{
        font-family: "Cairo";
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.32px;
    }
   

    .earn-point-cont {
        margin-top: 50px;
        position: relative;
        width: 100%;
        margin-bottom: 100px;
    }

    .earn-points-subtitle {
        font-weight: 500;
        font-size: 16px;
        margin-top: 10px;
        line-height: 16px;
    }
    .earn-points-subtitle.arabic{
        font-family: "Cairo";
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.32px;
    }

    .earn-points-text {
        font-weight: 300;
        font-size: 16px;
        margin-top: 10px;
        line-height: 16px;
    }
    .earn-points-text.arabic{
        font-family: "Cairo";
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.32px;
        margin-top: 0;
    }

    .earn-now-button {
        width: 75%;
        height: 40px;
        border-radius: 10px;
        color: #020202;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid rgb(0, 0, 0);
        background-color: transparent;
        outline: none;
        cursor: pointer;
        margin-top: 35px;
    }
    .earn-now-button.arabic{
        font-family: "Cairo";
        font-weight: 700;
        font-size: 12px;
        line-height: 10px;
        letter-spacing: -0.32px;
    }
    .earnPoints-Img {
        width: 100%;
    }

    .earn-point-container-mobile {
        width: 90%;
        height: 200px;
        background-color: rgb(252, 219, 204);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        justify-content: center;
    }

    .earn-point-mobile {
        font-family: 'Work Sans';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: uppercase;
        margin-top: 30px;
    }

    .good-deal-mobile {
        font-family: 'Work Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        margin-top: 20px;
    }

    .VIP-mobile {
        font-family: 'Work Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        margin-top: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .VIP2-mobile {
        font-family: 'Work Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .earn-now-button-mobile {
        width: 30%;
        height: 40px;
        border-radius: 10px;
        color: #020202;
        text-align: center;
        font-family: 'Work Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        padding: 15px;
        display: flex;
        border: 1px solid rgb(0, 0, 0);
        background-color: transparent;
        outline: none;
        cursor: pointer;
        margin-top: 18px;
        margin-bottom: 19px;
    }
    .test-product-div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .product-image-div{
        height: 65%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}