.error-container {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 177px;
}
.error-container img {
  max-width: 80%;
  height: auto;
}
.error-text {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #231f20;
  padding: 0 10px;
}
.dry-skin-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(252, 219, 204, 0.3);
  margin-top: 177px;
}
.dry-skin-mobile-view {
  display: none;
}

.dry-first-container {
  display: flex;
  height: fit-content;
  background-color: #fcdbcc;
}

.dry-first-container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 80px;
}

.dry-first-container-text-header {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  margin-bottom: 50px;
}

.dry-first-container-text-sub {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.32px;
}

.dry-first-container-image {
  width: 50%;
}
.dry-first-container-image img {
  width: 100%;
  height: 708px;
}

.dry-headline {
  font-family: "DM Serif Display";
  font-weight: 400;
  letter-spacing: -0.32px;
  color: #231f20;
  line-height: 48px;
  font-size: 48px;
  margin: 50px 80px;
  text-align: center;
}

.dry-second-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin: 20px 80px;
}

.dry-second--single-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 1 calc(33.333% - 40px);
  max-width: calc(33.333% - 40px);
}

.dry-second--single-container img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.dry-second--single-container span {
  display: block;
  margin-bottom: 5px;
  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
}

.dry-third-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0px;
}
.dry-headline-sub {
  font-family: "Work Sans";
  font-size: 48px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #231f20;
  margin-bottom: 30px;
}

.dry-headline-sub-1 {
  font-family: "Work Sans";
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.32px;
  color: #000000;
  text-align: center;
  padding: 0px 20%;
}

/* Product Skin */

.product-skin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  margin-bottom: 60px;
  .add-to-cart {
    width: 60%;
  }
  .sale-badge {
    top: 15%;
    right: 15%;
  }
}

.product-skin-name {
  font-family: "DM Serif Display";
  font-size: 28px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #231f20;
}
.product-skin img {
  width: 60%;
}
.product-skin-row {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.product-skin-light {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-align: center;
}

/* Desktop screens, larger than 1200px */
@media screen and (min-width: 1200px) {
  .error-container {
    padding: 40px 15px;
    margin-top: 177px;
  }
  .error-text {
    font-size: 20px;
  }
}
/* Medium screens, less than 1200px */
@media screen and (max-width: 1199px) {
  .error-container {
    padding: 30px 10px;
    margin-top: 177px;
  }

  .error-text {
    font-size: 18px;
  }
}
/* Small screens (tablets and large phones, 768px and up) */
@media screen and (max-width: 767px) {
  .error-container {
    padding: 30px 10px;
    padding-top: 125px;
  }
  .error-text {
    font-size: 18px;
  }
  .dry-skin-web-view {
    display: none;
  }
  .dry-skin-mobile-view {
    display: flex;
    flex-direction: column;
    background-color: rgba(252, 219, 204, 0.3);
    padding-top: 115px;
  }
  .dry-skin-img-mob img {
    width: 100%;
  }

  .dry-skin-head-mob {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-top: 40px;
    padding: 0px 20px;
  }
  .dry-skin-head-sub {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.32px;
    padding: 0px 20px;
    margin-top: 40px;
  }

  .dry-second-single-container-mob {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .dry-second-single-container-mob img {
    width: 100%;
    margin-top: 10px;
  }
  .dry-third-container-mob {
    margin: 40px 20px;
  }

  .dry-headline {
    font-size: 24px;
    line-height: 27px;
    margin: 0px;
    text-align: center;
  }

  .dry-headline-sub {
    font-size: 22px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 10px;
  }

  .dry-headline-sub-1 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 20px;
    padding: 0px;
  }
  .product-skin {
    margin-bottom: 40px;
    gap: 10px;
    margin-bottom: 60px;
    .add-to-cart {
      width: 100%;
    }
    .sale-badge {
      top: 5%;
      right: 5%;
    }
  }

  .product-skin-name {
    font-size: 18px;
    line-height: 16px;
  }
  .product-skin img {
    width: 100%;
  }
  .product-skin-row {
    gap: 10px;
  }
  .product-skin-light {
    font-size: 12px;
    line-height: 18px;
  }
}
