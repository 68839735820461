.wheel-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1101;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2%;
  gap: 15px;
  height: 95vh;
  width: 95vh;
}
.spin-close-button {
  width: 100%;
  position: absolute;
  right: -92%;
  top: 5%;
}
.spin-close-button img {
  width: 25px;
  height: 25px;
  flex: none;
  cursor: pointer;
}

.wheel-title {
  font-family: "DM Serif Display";
  font-size: 50px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
}

.wheel-subtitle {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #f98882;
}

.bhdLno {
  max-width: 550px !important;
  max-height: 550px !important;
}
.gTLZXx {
  position: absolute;
  z-index: 5;
  width: 17%;
  right: 6px;
  top: 15px;
  transform: rotate(45deg);
}

.reward-display {
  font-family: "Work Sans";
  font-size: 100px;
  font-weight: 700;
  line-height: 100px;
  text-align: center;
  color: #231f20;
}

.confetti-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Confines confetti to wheel-container width */
  height: 100%; /* Confines confetti to wheel-container height */
  pointer-events: none; /* Ensures confetti doesn't block interactions */
  overflow: hidden; /* Prevents overflow outside the wheel-container */
}
.promo-code-reward {
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.42px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  color: #f98882;
}

@media screen and (max-width: 768px) {
  .wheel-container {
    height: 65vh;
    width: 300px;
    padding: 0%;
    gap: 10px;
    top: 40%;
    padding: 5%;
  }

  .spin-close-button {
    right: -90%;
    top: 5%;
  }

  .spin-close-button img {
    width: 20px;
    height: 20px;
  }

  .wheel-title {
    font-size: 24px;
    line-height: 22px;
  }

  .wheel-subtitle {
    font-size: 12px;
    line-height: 18px;
  }

  .bhdLno {
    max-width: 300px !important;
    max-height: 300px !important;
  }

  .gTLZXx {
    width: 15%;
    right: 5px;
    top: 10px;
    transform: rotate(45deg);
  }

  .reward-display {
    font-size: 50px;
    line-height: 60px;
  }

  .promo-code-reward {
    font-size: 16px;
    line-height: 20px;
  }
}
