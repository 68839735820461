.track-order {
  margin-top: 177px;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracking-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.tracking-steps {
  display: flex;
  align-items: center;
  position: relative;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step-circle {
  width: 40px;
  height: 40px;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-size: 16px;
  color: #e0e0e0;
  transition: all 0.3s ease;
}

.step-circle.completed {
  border-color: #4caf50;
  color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1);
}

.step-label {
  margin-top: 10px;
  text-align: center;
}

.step-date {
  font-size: 12px;
  color: #888;
}

.step-line {
  width: 100px; /* Adjust the width as needed */
  height: 2px;
  background-color: #e0e0e0;
  position: absolute;
  left: 50%;
  top: 20px; /* Adjust position according to your design */
  transform: translateX(-50%);
  z-index: -1; /* Send line behind the steps */
}
