.error-container {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 177px;
}

.error-container img {
  max-width: 80%;
  height: auto;
}

.error-text {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #231f20;
  padding: 0 10px;
}

.product-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(252, 219, 204, 0.3);
  padding: 80px;
  margin-top: 177px;
}

.shop-all {
  color: rgba(226, 164, 136, 1);
  font-family: "Work Sans";
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.32px;
  margin-bottom: 40px;
  font-size: 40px;
  width: 100%;
}

.products-column {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.product-item {
  flex: 1 1 calc(33.333% - 30px);
  margin-bottom: 20px;
}

.product-item a {
  text-decoration: none;
  color: inherit;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 460px;
  height: 760px;
  position: relative;
}

.product img {
  height: 560px;
  width: 450px;
  border-radius: 10px;
}

.sale-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: rgba(252, 219, 204);

  color: #020202;
  padding: 10px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 120px;
  height: 60px;
  text-align: center;

  font-size: 18px;
  font-family: "DM Serif Display";
  font-weight: 400;
  line-height: 20px;

  z-index: 999;
}

.hearts-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.products-name {
  font-family: "DM Serif Display";
  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #231f20;
}

.add-to-cart {
  border-radius: 10px;
  color: #020202;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #020202;
  width: 100%;
  height: 71px;
}

.products-mobile-view {
  display: none;
}

/* Desktop screens, larger than 1200px */
@media screen and (min-width: 1200px) {
  .error-container {
    padding: 40px 15px;
    margin-top: 177px;
  }
  .error-text {
    font-size: 20px;
  }
  .product-item {
    flex: 0 1 calc(33.333% - 20px);
  }
}

/* Medium screens, less than 1200px */
@media screen and (max-width: 1199px) {
  .error-container {
    padding: 30px 10px;
    margin-top: 177px;
  }

  .error-text {
    font-size: 18px;
  }
  .product-item {
    flex: 0 1 calc(50% - 30px);
  }
}

/* Small screens (tablets and large phones, 768px and up) */
@media screen and (max-width: 767px) {
  html,
  body {
    margin: 0;
    padding: 0;
  }
  .error-container {
    padding: 30px 10px;
    padding-top: 125px;
  }

  .error-text {
    font-size: 18px;
  }
  .products-web-view {
    display: none;
  }

  .products-mobile-view {
    display: block;
    background-color: rgba(252, 219, 204, 0.3);
    padding: 5px 20px;
    padding-top: 135px;
  }

  .shop-all {
    color: #231f20;
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .products-column-mobile,
  .products-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-item-mobile,
  .product-item {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 30px;
  }

  .product-mobile,
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 12px;
    height: fit-content;
    position: relative;
  }

  .sale-badge {
    padding: 5px;

    width: fit-content;
    min-width: 120px;

    height: 50px;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .product-mobile img,
  .product img {
    border-radius: 10px;
    width: 100%;
    max-width: 352px;
  }

  .products-name {
    font-size: 18px;
    line-height: 18px;
  }

  .add-to-cart-product-mobile,
  .add-to-cart {
    border-radius: 10px;
    color: #020202;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100%;
    height: 48px;
  }
}
