.mobile-view-profile {
  display: none;
}

.web-view-profile {
  padding: 100px;
  margin-top: 177px;
}

.profile-big-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.profile-edit {
  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-decoration: underline;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.profile-edit button {
  background-color: transparent;
  border: none;
}

.profile-big-column > .row {
  display: flex;
  width: 80%;
  margin-bottom: 30px;
}

.profile-big-column > .row > div:first-child {
  flex: 0 0 15%;
}

.profile-big-column > .row > div:last-child {
  flex: 1;
}

.profile-name {
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.32px;
  color: #e3a689;
  font-family: "Work Sans";
  font-size: 36px;
}

.profile-left-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
a {
  text-decoration: none;
  color: inherit;
}
.profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e3a689;
  padding: 10px;
  margin: 0 auto;
  font-weight: 400;
  line-height: 16px;
  height: 62px;
  width: 127px;
  font-size: 22px;
}

.active-profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fcdbcc; /* New background color */
  padding: 10px;
  margin: 0 auto;
  font-weight: 400;
  line-height: 16px;
  height: 62px;
  width: 127px;
  font-size: 22px;
}

/* Account container */

.profile-account-column {
  display: flex;
  flex-direction: column;
}

.profile-column {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans";
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #818181;
  border-radius: 10px;
  border: 1px solid #e3a689;
  padding-top: 10px;
  padding-bottom: 10px;
}

.profile-single-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 30px;
  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #818181;
}

.profile--single-column-details {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #818181;
}

.profile-column input {
  padding: 5px;
}
.password-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-column-sub {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #818181;
}
.profile-redeem-points {
  font-family: "Work Sans";
  font-size: 32px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #e3a689;
  margin-top: 50px;
}

.profile-rewards-buttons {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 20px;
}

.redeem-points-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #fcdbcc;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  border: none;
  padding: 15px 50px;
  height: 71px;
  font-size: 24px;
  width: 100%;
  color: #020202;
}

.rewards-history-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  border: 2px solid #fcdbcc;
  padding: 15px 50px;
  height: 71px;
  font-size: 24px;
  width: 100%;
  color: #020202;
}
/************************************************************/

/* Wishlist container */
.profile-wishlist {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.wishlist-item {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.wishlist-item a {
  text-decoration: none;
  color: inherit;
}
.wishlist-product {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 480px;
  height: 750px;
  position: relative;
}
.wishlist-product img {
  height: 560px;
  border-radius: 10px;
}
.wishlist-products-name {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #231f20;
}
.wishlist-remove {
  font-family: Work Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #818181;
  text-decoration: underline;
}

.no-wishlist-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans", sans-serif; /* Specify a fallback font */
  line-height: 1.6;
  border-radius: 10px;
  border: 1px solid #e3a689;
  color: #020202;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  height: fit-content;
  font-size: 24px;
  width: 100%;
  margin: auto 50px;
}
/************************************************************/

/* Single order container */

.profile-single-order-details {
  display: flex;
  align-items: center;
  gap: 50px;

  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-align: left;
}
.profile-single-order-details-column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px;
  gap: 10px;

  color: #818181;
  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-align: left;
}

/************************************************************/

/* order container */

.profile-single-order {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #818181;
}
.profile-single-order:last-child {
  border-bottom: none;
}
.profile-single-first-order {
  display: flex;
  gap: 40px;
}

.profile-single-order img {
  width: 102px;
}

.profile-single-order-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #818181;
}
.profile-single-second-order {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #818181;
}
.profile-single-second-order div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.profile-single-second-order img {
  width: 20px;
  height: 20px;
}

/************************************************************/

.web-view-profile a {
  text-decoration: none;
  color: inherit;
}

.mobile-view-profile a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 767px) {
  .web-view-profile {
    display: none;
  }
  .mobile-view-profile {
    padding-top: 135px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
  }
  .profile-name {
    font-size: 27px;
  }
  .profile-mobile-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .profile-logout {
    font-family: "Work Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #e3a689;
    text-decoration: underline;
  }
  .profile-left-button {
    flex-direction: row;
    gap: 2%;
    margin-top: 20px;
  }
  .profile-box {
    height: 40px;
    font-size: 16px;
    width: 82px;
  }

  .active-profile-box {
    height: 40px;
    font-size: 16px;
    width: 82px;
  }
  .profile-column {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .profile-single-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
  .profile-inner-column {
    font-size: 18px;
  }
  .profile-inner-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .profile-column-underline {
    font-size: 14px;
  }

  .profile-column-sub {
    font-size: 14px;
  }
  .profile-redeem-points {
    font-size: 28px;
  }
  .profile-rewards-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 3%;
  }
  .redeem-points-button {
    height: 48px;
    padding: 0;
    font-size: 16px;
  }

  .rewards-history-button {
    height: 48px;
    padding: 0;
    font-size: 16px;
  }
  .profile-wishlist-mobile {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 30px;
  }
  .wishlist-product {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: fit-content;
    gap: 10px;
  }
  .wishlist-product img {
    width: 92px;
    height: 97.18px;
  }
  .wishlist-mobile-inner-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .wishlist-products-name {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.3199999928474426px;
    text-align: left;
  }
  .wishlist-price {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: #000000;
    text-align: left;
  }
  .add-to-basket {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: transparent;
    font-weight: 500;
    line-height: 16px;
    border: 1px solid #fcdbcc;
    font-size: 16px;
    height: 40px;
    width: 80%;
  }
  .wishlist-remove {
    font-size: 14px;
  }
  .no-wishlist-items {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #020202;
    padding: 15px;
  }

  .profile-single-order-details {
    gap: 20px;
    font-size: 16px;
  }
  .profile-single-order-details-column {
    margin: 0px;
    gap: 0px;
    color: #818181;
    font-size: 16px;
  }

  .profile-single-order {
    padding: 20px 10px;
  }

  .profile-single-first-order {
    gap: 20px;
  }

  .profile-single-order img {
    width: 70px;
  }

  .profile-single-order-column {
    gap: 15px;
    font-size: 16px;
    line-height: 18px;
  }
  .profile-single-second-order {
    gap: 15px;
    font-size: 16px;
    line-height: 16px;
  }

  .profile-single-second-order img {
    width: 20px;
    height: 20px;
  }
}
