.reviews-container {
  padding: 20px;
  background-color: #fffcf5;
  padding-top: 135px;
}

.headline-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headline {
  color: #231f20;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.32px;
}

.write-review {
  color: #ede196;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.32px;
  text-decoration: underline;
}

.ratings {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
}

.ratings-details {
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  width: 70%;
}

.ratings-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.number {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0px 10px;
}

.big-number {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin: 0px 10px;
}

.authenticity {
  color: #888886;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  margin-top: 15px;
}

.reviews {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.title {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.32px;
  margin-top: 15px;
}

.hearts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hearts-container img {
  margin-right: 2px;
}

.comment {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
}
