@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
.rewards-container {
  display: flex;
  flex-direction: column;
  margin-top: 177px;
}

.rewards-mobile {
  display: none;
}

.rewards-first-container {
  display: flex;
  background-color: #fcdbcc;
}

.rewards-first-container-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex: 1;
}

.rewards-first-container-left span {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
}
.rewards-first-container-left.arabic span {
  font-style: normal;
}

.rewards-first-container-buttons {
  display: flex;
  gap: 20px;
}
.rewards-first-container-buttons button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid black;
  background-color: transparent;
  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  width: 132px;
  height: 56px;
  color: #020202;
}

.rewards-right-container {
  flex: 1;
}
.rewards-right-container img {
  width: 100%;
}

.rewards-headline {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-style: italic;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
  margin-bottom: 50px;
}
.rewards-headline.arabic {
  font-style: normal;
  font-size: 40px;
}

.rewards-second-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 100px;
}

.rewards-second-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 85px;
  border-radius: 10px;
  border: 1px solid #e3a689;
  font-family: "Work Sans";
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
  height: 230px;
  flex: 1 1 calc(33.333% - 30px);
}
.rewards-second-container.arabic div {
  font-size: 36px;

  font-weight: 600;
}

.rewards-third-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;
  margin-bottom: 50px;
  gap: 30px;
}

.rewards-third-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e3a689;
  gap: 10px;

  font-family: "Work Sans";
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  text-align: center;

  flex: 1 1 calc(50% - 30px);
  height: 680px;
}

.rewards-third-container div img {
  width: 280px;
  height: 280px;
}

.rewards-terms {
  font-family: "Work Sans";
  text-decoration: underline;
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #f6d4c5;
  margin-bottom: 50px;
}

.rewards-web a {
  text-decoration: none;
  color: inherit;
}

.rewards-mobile a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 767px) {
  .rewards-web {
    display: none;
  }
  .rewards-mobile a {
    text-decoration: none;
    color: inherit;
  }
  .rewards-mobile {
    display: block;
    padding-top: 115px;
  }

  .rewards_vip_mob img {
    height: 437px;
    width: 100%;
  }

  .join-vip-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #fcdbcc;
    padding: 50px 20px;
    margin-bottom: 50px;
  }

  .rewards-headline-mob {
    font-family: "DM Serif Display";
    font-size: 27px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    text-align: center;
  }
  .rewards-headline-mob.arabic {
    font-style: normal;
    line-height: 32px;
  }

  .rewards-first-container-buttons-mob {
    display: flex;
    gap: 10px;
  }

  .rewards-first-container-buttons-mob button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid black;
    background-color: transparent;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    width: 110px;
    height: 46px;
    color: #020202;
  }

  .rewards-second-container-mob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    gap: 30px;
    margin-bottom: 40px;
  }

  .rewards-second-container-mob div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 1px solid #e3a689;

    font-family: "Work Sans";
    font-size: 20px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.32px;
    text-align: center;

    height: 118px;
    width: 100%;
  }

  .rewards-third-container-mob {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    gap: 20px;
  }

  .rewards-third-container-mob div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e3a689;

    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.32px;
    text-align: center;

    height: 321px;
  }

  .rewards-third-container-mob div img {
    width: 142.18px;
    height: 138.29px;
  }

  .rewards-terms {
    font-size: 18px;
    text-align: center;
    color: #f6d4c5;
    margin-bottom: 20px;
  }
}
