.policies-container {
    display: flex;
    flex-direction: column;
    padding: 80px;
    margin-top: 177px;

    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.32px;
    color: #000000;
    
}

.policies-title {
    font-family: 'Work Sans', sans-serif;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.3199999928474426px;
    text-align: center;
    margin-bottom: 30px; 
  }

  .policies-mob{
    display: none;
  }
  


@media screen and (max-width: 767px) {
  .policies-web{
    display: none;
  }

  .policies-mob{
    display: block;
    padding-top: 135px;
    padding-left:20px;
    padding-right: 20px;
    padding-bottom: 20px;

  }

  .policies-container-mob{
    display: flex;
    flex-direction: column;

    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #000000;
    text-align: left;
  }
  .policies-title {
    font-size: 24px;
  }
}