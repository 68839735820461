@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
.error-container {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 177px;
}
.error-container img {
  max-width: 80%;
  height: auto;
}
.error-text {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  color: #231f20;
  padding: 0 10px;
}
.about-us-mobile-view {
  display: none;
}
.about-us-container {
  display: flex;
  flex-direction: column;
  margin-top: 177px;
}
.about-us-background {
  margin-bottom: 50px;
}
.about-us-background img {
  width: 100%;
}

.about_us_row {
  display: flex;
  justify-content: center;
  margin: 50px 80px;
}

.about_us_first_column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
}
a {
  text-decoration: none;
  color: inherit;
}
.about_us_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e3a689;
  padding: 10px;
  margin: 0 auto;
  font-weight: 400;
  line-height: 16px;
  height: 62px;
  width: 190px;
  font-size: 22px;
  font-family: "Work Sans";
}
.about_us_box.arabic {
  font-family: "Cairo";
}
.brand-value {
  font-family: "Work Sans";
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  /* text-align: left; */
  flex: 2;
}
.brand-value.arabic {
  font-family: "Cairo";
}
.brand-value-title {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  /* text-align: left; */
  margin-bottom: 15px;
}
.brand-value-title {
  font-family: "Cairo";
}

.brand-value-head {
  margin-bottom: 3px;
}

.brand-value-text {
  font-weight: 400;
  margin-bottom: 20px;
}

.promise {
  flex: 2;
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  /* text-align: left; */
}
.promise.arabic {
  font-family: "Cairo";
}

.promise-text {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  /* text-align: left; */
}
.promise-text.arabic {
  font-family: "Cairo";
  font-weight: 300;
}

/* Desktop screens, larger than 1200px */
@media screen and (min-width: 1200px) {
  .error-container {
    padding: 40px 15px;
    margin-top: 177px;
  }
  .error-text {
    font-size: 20px;
  }
}
/* Medium screens, less than 1200px */
@media screen and (max-width: 1199px) {
  .error-container {
    padding: 30px 10px;
    margin-top: 177px;
  }

  .error-text {
    font-size: 18px;
  }
}
/* Small screens (tablets and large phones, 768px and up) */
@media screen and (max-width: 767px) {
  .error-container {
    padding: 30px 10px;
    padding-top: 125px;
  }
  .error-text {
    font-size: 18px;
  }
  .about-us-web-view {
    display: none;
  }
  .about-us-mobile-view {
    display: block;
    padding-top: 115px;
  }
  .about-us-background {
    margin-bottom: 50px;
  }

  .about_us_row_mob {
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
  }

  .about_us_box_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #e3a689;
    padding: 5px;
    font-weight: 400;
    line-height: 16px;
    font-size: 16px;
    height: 37px;
  }
  .about_us_box_mob {
    font-family: "Cairo";
    width: 100px;
  }
  .brand-value-mob {
    margin: 20px 20px;
  }
  .brand-value-title-mob {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.32px;
    /* text-align: left; */
    margin-bottom: 15px;
  }
  .brand-value-title-mob.arabic {
    font-family: "Cairo";
  }

  .brand-value-mob-head {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    /* text-align: left; */
  }
  .brand-value-mob-head.arabic {
    font-family: "Cairo";
    font-weight: 500;
  }
  .brand-value-mob-text {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    /* text-align: left; */
    margin-bottom: 15px;
  }
  .brand-value-mob-text.arabic {
    font-family: "Cairo";
    font-weight: 300;
  }

  .promise-mob {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.32px;
    /* text-align: left; */
    margin: 20px;
  }
  .promise-mob.arabic {
    font-family: "Cairo";
    font-weight: 500;
  }

  .promise-mob-text {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    /* text-align: left; */
  }
  .promise-mob-text.arabic {
    font-family: "Cairo";
    font-weight: 300;
  }
}
