.phone-input-wrapper {
    display: flex;
    align-items: center;
    background-color: #fcdccc4d !important;
    border-radius: 10px;
    padding: 0px 10px;
  }
  .phone-input-wrapper input {
    background-color: transparent !important;
    border: none;
  }
  .phone-input-wrapper button {
    background-color: transparent !important;
    border: none;
  }
  .react-international-phone-input-container {
    width: 100%;
    gap: 2%;
  }
  .react-international-phone-input {
    width: 80% !important;
    background-color: rgb(252, 219, 204, 0.3) !important;
    height: 48px !important;
    border-radius: 10px !important;
    border: none !important;
  }
  .react-international-phone-country-selector {
    width: 10%;
  }

  .react-international-phone-country-selector-button {
    width: 100%;
    background-color: rgb(252, 219, 204, 0.3) !important;
    height: 48px !important;
    border-radius: 10px !important;
    border: none !important;
  }

  .react-international-phone-country-selector-dropdown {
    border: none !important;
    outline: none !important;
  }
@media (min-width: 768px) {
    .mobile-order-Info-page {
        display: none;
    }
}

@media (max-width: 767px) {
    .mobile-order-Info-page {
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        padding-top: 115px;
    }

    .min-height {
        min-height: 600px;
    }

    .row-drop {
        height: 6px;
        width: 100%;
        display: flex;
        flex-direction: row;
        z-index: 7;
    }

    .checkbox-rose {
        padding-left: 0%;
    }

    .red {
        background-color: rgb(252, 219, 204);
        width: 30%;
    }

    .light-gray {
        width: 70%;
    }

    .ordermap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 10px;
    }

    .Information {
        font-family: Work Sans;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
    }

    .shipping {
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);

    }

    .payment {
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
    }

    .header {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        justify-content: space-between;
        padding-left: 3%;
        padding-right: 4%;

    }

    .contact-title {
        font-size: 18px;
        font-weight: 500;
        font-family: Work Sans;
        letter-spacing: -0.32px;
        line-height: 16px;
        color: rgba(129, 129, 129, 0.67);
        margin-bottom: 10px;
    }

    .shipping-title {
        font-size: 18px;
        font-weight: 500;
        font-family: Work Sans;
        letter-spacing: -0.32px;
        line-height: 16px;
        color: rgba(129, 129, 129, 0.67);
        margin-bottom: 15px;
        margin-top: 25px;
    }

    .signUp-logIn {
        font-size: 14px;
        font-weight: 400;
        font-family: Work Sans;
        letter-spacing: -0.32px;
        line-height: 16px;
        color: rgba(129, 129, 129, 1);
    }

    .underline-text {
        text-decoration: underline;
    }

    .order-Information {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding-left: 3%;
        padding-right: 3%;

    }

    .receive-emails-orderInfo {

        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #231f20;
        display: flex;
        align-items: flex-start;
        gap: 5px;
        margin-left: 2%;
        margin-top: 10px;
    }
    .receive-emails-orderInfo2 {

        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #231f20;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-left: 2%;
        margin-top: 10px;
    }

    .save-info {
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        line-height: -0.32;
        color: #231f20;
        display: flex;
        align-items: center;
        gap: 0px;

        margin-bottom: 20px;
    }


    .select {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
        height: 48px;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 0.5rem;
        border: 2px solid transparent;
        border-radius: 3px;
        transition: box-shadow 0.2s ease-in;
        color: inherit;
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.32px;
    }


    .selector {
        background-color: rgb(254, 243, 239);

        height: 48px;
        border-radius: 10px;
        display: flex;
        font-family: Work Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.32px;
        margin-bottom: 5px;
    }


    .delect {
        align-items: center;
        display: flex;
        width: 100%;
    }

    .css-en0rrh-option {
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

    /* If you want to remove the border and box-shadow from the dropdown menu itself */
    .css-1elthqx-menu {
        box-shadow: none !important;
    }



    .shipping-button {
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(252, 219, 204);
        border-radius: 10px;
        font-family: Work Sans;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.32px;
        margin-top: 20px;
    }

    .Return-Cart-Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }
   

    .arrow-orderInfo {
        transform: scaleX(-1);
    }
    .arrow.arabic{
        transform: scaleX(1);
    }

    .return-cart {
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
        text-decoration: underline;
        margin-left: 1%;
    }


}