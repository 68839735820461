.shipping-page-Container{
    padding-left: 3%;
    padding-right: 3%;
}
.edit-shipping-page{
    display: flex;
    margin-bottom: 5px;
    padding-right: 2%;
   justify-content: flex-end;
   font-size: 14px;
   font-family: Work Sans;
   font-weight: 400;
   line-height: 16px;
   letter-spacing: -0.32px;
   text-decoration: underline;
   color: rgba(129, 129, 129, 1);

}

.first-box-shipping-page {
    height: 240px;
    width: 100%;
    border: 1px solid rgba(252, 219, 204, 1); 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 4% 4%;
}
.first-box-title-shipping-page{
    font-size: 18px;
    font-family: Work Sans;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(129, 129, 129, 0.67);
}
.first-box-email-shipping-page{
    font-size: 14px;
    font-family: Work Sans;
    font-weight: 400;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(129, 129, 129, 0.67);
    margin-top: 15px;
}

.black-line-shipping-page{
    width: 100%;
    height: 1px;
    background-color: rgba(170, 170, 170, 1);
}
.shipping-method-text-page{
    font-size: 18px;
    font-family: Work Sans;
    font-weight: 500;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(129, 129, 129, 0.67);
    margin-top: 30px;
    margin-bottom: 30px;
}


.second-box-shipping-page{
    display: flex;
    flex-direction: row;
    height: 120px;
    width: 100%;
    border: 1px solid rgba(252, 219, 204, 1); 
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 6% 4%;
    margin-bottom: 30px;
  }

  .second-box-shipping-page.arabic{
    padding: 6% 7%;
  }

.second-box-shipping-page-img{
   
    display: flex;
    align-items: center;
}

  
  .second-box-shipping-page-img img {
    position: absolute;
    top: 50%;
    
    transform: translate(-50%, -50%);
    margin-left: 10px;
  }
  
  .second-box-shipping-page-img img:first-child {
    z-index: 1; /* Make the rose circle behind */
  }
  
  .second-box-shipping-page-img img:last-child {
    z-index: 2; /* Make the white circle in front */
  }
  
.second-box-shipping-page-text{
    margin-left: 8px;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    color: rgba(129, 129, 129, 1);
    line-height: -0.32px;
    display: flex;
    align-items: center;
}
.second-box-shipping-page-text{
    margin-right: 8px;
}
.second-box-shipping-page-price{
    margin-left: auto; /* Align text to the rightmost part of the box */
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    color: rgba(129, 129, 129, 1);
    line-height: -0.32px;
    display: flex;
    align-items: center;
}

.continue-to-payment-button{
    width: 100%;
    display: flex;
    align-items: center;
    
}
.button-container-shipping .btn:active {
    background-color: white;
    border: 1px solid rgba(252, 219, 204, 1); /* Set border color when button is clicked */
}


@media (min-width: 768px) {
    .mobile-shipping-page{
        display: none;
    }
    .btn{
        width:100%;
       
    }
    .pc-shipping-page{
        width: 100%;
        height: 100vh;
        background-color: rgb(255, 255, 255);
        margin-top: 177px;
        padding: 5% 5%;
        display: flex;
        flex-direction: row;
    }
    .Left-Align-shipping {
        width: 50%;
        height: 100%;
        padding-left: 3%;
        padding-right: 3%;
    }

    .Right-Align-shipping {
        width: 50%;
        height: 100%;
        padding-left: 3%;
        padding-right: 3%;
    }
    .button-container-shipping{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .single-product-coloum-shipping {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 3%;
    }
    .single-product-shipping {
        display: flex;
        flex-direction: row;
        height: 130px;
        width: 99%;
        /* padding-right: 0.75%; */
        margin-bottom: 3%;
    }
    .single-coloum-shipping {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5%;
        width: 100%;
    }
    .single-coloum-shipping.arabic{
        margin-right: 5%;
        margin-left: 0;
    }
    .product-name-shipping {
        font-size: 16px;
        font-family: Work Sans;
        font-weight: 600;
        letter-spacing: -0.32px;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        text-align: left;
    }
    .product-price-shipping {
        font-size: 18px;
        font-family: Work Sans;
        line-height: 16px;
        font-weight: 500;
    }

    .product-counter-shipping {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .product-remove-shipping {
        font-size: 14px;
        font-family: Work Sans;
        font-weight: 400;
        color: rgba(129, 129, 129, 1);
        text-decoration: underline;
        Right: 0;
        display: flex;
        align-items: flex-end;
    }

   
}

@media (max-width: 767px) {
    .mirror{transform: scaleX(-1);}
    .mobile-shipping-page {
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        padding-top: 115px;
      
    }
    .pc-shipping-page{
        display: none;
    }
 

    .row-drop2 {
        height: 6px;
        width: 100%;
        display: flex;
        flex-direction: row;
        z-index: 7;
    }

    .red2 {
        background-color: rgb(252, 219, 204);
        width: 70%;
    }

    .light-gray2 {
        width: 30%;
    }
    .ordermap2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 10px;
    }

    .Information2 {
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
    }

    .shipping2 {
        font-family: Work Sans;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);

    }

    .payment2 {
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
    }
    .edit-shipping-page{
        margin-top: 35px;
    }

    .continue-to-payment-button{
        width: 70%;
        display: flex;
        align-items: center;
        
    }
    .btn{
        width: 100%;
    }
    .Return-Order-Info-Container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .arrow {
        transform: scaleX(-1);
    }
    .return-order-info{
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.32px;
        color: rgba(129, 129, 129, 1);
        text-decoration: underline;
        margin-left: 1%;

    }
}