.input-bar {
  position: relative; /* Add relative positioning */
  width: 100%;
  padding: 10px 20px;
  border-radius: 9999px;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d1d5db;
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-search {
  width: 24px;
  height: 24px;
}
.search-bar{
  width: 100%;
  border: none;
}

.search-bar:focus {
  outline: none;
}

.hint-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: max-content; /* Set a max height for the hint list */
  overflow-y: auto; /* Enable vertical scrolling if the list is too long */
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  /* border: 1px solid #d1d5db; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */

  top: 100%; /* Position the hint list below the input bar */
  left: 0;
  z-index: 100; /* Ensure the hint list is above other elements */
  margin-top: 10px;
}

.hint-list li {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.hint-list li:hover {
  background-color: #f0f4f8;
}


.popular-options {
  padding: 10px;
  cursor: default; 
  font-size: 16px; 
  /* font-weight: bold;  */
}

.popular-options .hint-text {
  font-size: inherit; 
  font-weight: inherit; 
}

@media (max-width: 767px) {
  .input-bar {
    padding: 5px 10px;
    gap: 10px;
  }
  .hint-list {
    font-size: 14px; 
  }
  .icon-search{
    display: none;
  }
  
}