.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1101;

  display: flex;
  width: 1000px;
}

.popup-image {
  flex: 1;
}

.popup-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 60px;
  gap: 10px;
}

.popup-title {
  font-family: "DM Serif Display";
  font-size: 50px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.32px;
}

.popup-subtitle {
  font-family: "Work Sans";
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
}

.popup-button-container {
  width: 100%;
  display: flex;
}
.popup-button {
  border-radius: 10px;
  background-color: transparent;
  height: 56px;
  width: 132px;
  color: #020202;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border: 2px solid #020202;
}

.popup-close-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.popup-close-button img {
  width: 30px;
  height: 30px;
  flex: none;
  cursor: pointer;
}
.popup-close-button-white {
  display: none;
}

@media screen and (max-width: 767px) {
  .popup-container {
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 290px;
  }

  .popup-content {
    align-items: flex-start;
    padding: 15px;
    gap: 25px;
  }

  .popup-close-button {
    display: none;
  }

  .popup-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.32px;
    text-align: left;
  }

  .popup-subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
  }

  .popup-button {
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #020202;

    color: #020202;
    height: 48px;
    width: 101px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  .popup-close-button-white {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .popup-close-button-white img {
    width: 30px;
    height: 30px;
  }
}
