.btn {
  color: black;
  background-color: rgba(252, 219, 204, 1);
  padding: 0.5rem 1.5rem;
  font-size: 1.3rem;
  border-radius: 5px;
  font-family: Work Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  height: 48px;
  margin-top: 30px;
  width: 100%;
}
.phone-input-wrapper {
  display: flex;
  align-items: center;
  background-color: #fcdccc4d !important;
  border-radius: 10px;
  padding: 0px 10px;
}
.phone-input-wrapper input {
  background-color: transparent !important;
  border: none;
}
.phone-input-wrapper button {
  background-color: transparent !important;
  border: none;
}

.button-cart-container .btn:active {
  background-color: white;
  border: 1px solid rgba(252, 219, 204, 1); /* Set border color when button is clicked */
}

.btn:hover {
  color: rgb(0, 0, 0);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
  border: 1px solid rgba(252, 219, 204, 1);
  /* Add border when hovering */
}

.btn.disabled,
.btn.disabled:hover {
  background-color: rgba(196, 192, 192, 1);
  border: 1px solid rgba(196, 192, 192, 1);
  color: white;
  cursor: not-allowed;
  width: 100%;
}
.button-cart-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shipping-button-cart-pc {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 219, 204);
  border-radius: 10px;
  font-family: Work Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
}
.shipping-button-cart-mobile {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(252, 219, 204);
  border-radius: 10px;
  font-family: Work Sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
}
.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3%;
  margin-right: 3%;
  font-size: 14px;
  font-weight: 400;
  font-family: Work Sans;
  line-height: 16px;
  color: rgba(2, 2, 2, 1);
  height: 50vh;
}

.empty-cart-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  width: 100%;
  border: 1px solid rgba(252, 219, 204, 1);
  border-radius: 10px;
  padding: 2% 2%;
}

.empty-cart-message-underline {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .mobile-cart {
    display: none;
  }

  .pc-cart {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-top: 177px;
    padding: 5% 5%;
    display: flex;
    flex-direction: row;
  }

  .react-international-phone-input-container {
    width: 100%;
    gap: 2%;
  }
  .react-international-phone-input {
    width: 80% !important;
    background-color: rgb(252, 219, 204, 0.3) !important;
    height: 48px !important;
    border-radius: 10px !important;
    border: none !important;
  }
  .react-international-phone-country-selector {
    width: 10%;
  }

  .react-international-phone-country-selector-button {
    width: 100%;
    background-color: rgb(252, 219, 204, 0.3) !important;
    height: 48px !important;
    border-radius: 10px !important;
    border: none !important;
  }

  .react-international-phone-country-selector-dropdown {
    border: none !important;
    outline: none !important;
  }

  .Left-Align-cart {
    width: 50%;
    height: 100%;
  }
  .empty-cart {
    width: 100%;
    font-size: 14px;
  }
  .empty-cart-message {
    width: 791px;
    height: 71px;
    padding: 0%;
  }

  .Right-Align-cart {
    width: 50%;
    height: 100%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .single-product-coloum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3%;
  }

  .single-product-cart {
    display: flex;
    flex-direction: row;
    height: 130px;
    width: 99%;
    /* padding-right: 0.75%; */
    margin-bottom: 3%;
  }

  .single-coloum {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5%;
    width: 100%;
  }
  .single-coloum.arabic {
    margin-left: 0;
    margin-right: 5%;
  }

  .product-name {
    font-size: 16px;
    font-family: Work Sans;
    font-weight: 600;
    letter-spacing: -0.32px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }

  .product-price {
    font-size: 18px;
    font-family: Work Sans;
    line-height: 16px;
    font-weight: 500;
  }

  .product-conter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-remove {
    font-size: 14px;
    font-family: Work Sans;
    font-weight: 400;
    color: rgba(129, 129, 129, 1);
    text-decoration: underline;
    right: 0;
    display: flex;
    align-items: flex-end;
  }

  .subtotal-container-pc {
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
  }

  .row-pc {
    margin-left: 2%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title-pc {
    font-family: Work Sans;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 16px;
  }

  .value-pc {
    font-family: Work Sans;
    font-weight: 500;
    font-size: 20;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(0, 0, 0, 1);
  }

  .header-cart-pc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 3%;
    padding-right: 4%;
    align-items: flex-end;
    margin-bottom: 25px;
  }

  .contact-title-cart-pc {
    font-size: 36px;
    font-weight: 500;
    font-family: Work Sans;
    letter-spacing: -0.32px;
    line-height: 24px;
    color: rgba(129, 129, 129, 0.67);
  }

  .signUp-logIn-cart-pc {
    font-size: 14px;
    font-weight: 400;
    font-family: Work Sans;
    letter-spacing: -0.32px;
    line-height: 16px;
    color: rgba(129, 129, 129, 1);
  }

  .underline-text-cart-pc {
    text-decoration: underline;
  }

  .order-Information-cart-pc {
    display: flex;
    flex-direction: column;
    /* margin-top: 20px; */
    padding-left: 3%;
    padding-right: 3%;
  }

  .receive-emails-cart-pc {
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    line-height: -0.32;
    color: #231f20;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .shipping-title-cart-pc {
    font-size: 36px;
    font-weight: 500;
    font-family: Work Sans;
    letter-spacing: -0.32px;
    line-height: 24px;
    color: rgba(129, 129, 129, 0.67);
    margin-bottom: 25px;
    /* margin-top: 25px; */
    margin-left: 1%;
  }

  .selector-cart-pc {
    background-color: rgb(254, 243, 239);

    height: 48px;
    border-radius: 10px;
    display: flex;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    margin-bottom: 5px;
  }

  .delect-cart-pc {
    align-items: center;
    display: flex;
    width: 100%;
  }

  .save-info-cart-pc {
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #231f20;
    display: flex;
    align-items: center;
    gap: 0px;
    margin-top: 10px;
  }

  .checkbox-rose-cart-pc {
    padding-left: 0%;
  }

  .sign-up-info-cart-pc {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    position: relative;
    color: rgb(129, 129, 129);
  }

  .return-shopping-pc {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgba(129, 129, 129, 1);
    text-decoration: underline;
    margin-left: 1%;
    margin-top: 20px;
  }
  .return-shopping-pc.arabic {
    text-align: left;
  }

  input-field input {
    width: 100%;
    height: 48px;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0.5rem;
    border: 2px solid transparent;
    border-radius: 3px;
    transition: box-shadow 0.2s ease-in;
    color: inherit;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    color: rgb(171, 170, 170);
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: rgb(129, 129, 129) !important;
    caret-color: rgb(129, 129, 129) !important;
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
  }
}

@media (max-width: 767px) {
  .mobile-cart {
    width: 100%;
    /* height: 100%; */
    min-height: 500px;
    padding-top: 115px;
  }

  .pc-cart {
    display: none;
  }

  .cart-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 3px rgb(217, 209, 205);
    height: 100%;
    min-height: 500px;
  }
  .button-cart-container {
    margin-bottom: 15px;
  }
  .btn {
    margin-top: 15px;
  }
  .my-cart {
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.32;
    color: rgba(129, 129, 129, 1);
    margin: 5%;
  }

  .single-product-cart {
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    margin-right: 5%;
    height: 130px;
    margin-bottom: 3%;
  }

  .single-coloum {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 7%;
    width: 70%;
  }

  .single-coloum.arabic {
    margin-left: 0;
    margin-right: 7%;
  }

  .product-name {
    font-size: 16px;
    font-family: Work Sans;
    font-weight: 600;
    letter-spacing: -0.9px;
    line-height: 18px;
    width: 100%;
  }

  .product-price {
    font-size: 18px;
    font-family: Work Sans;
    line-height: 16px;
    font-weight: 500;
  }

  .product-conter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .product-remove {
    font-size: 14px;
    font-family: Work Sans;
    font-weight: 400;
    color: rgba(129, 129, 129, 1);
    text-decoration: underline;
    right: 0;
    display: flex;
    align-items: flex-end;
  }

  .counter-box-cart {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #ece095;
    height: 48px;
    width: 100px;
    padding: 10px;
  }

  .counter-container-cart {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
  }

  .counter-container-cart button {
    background: none;
    border: none;
    cursor: pointer;
    color: #020202;
    font-family: "Work Sans", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
    margin: 0 0px;
    outline: none;
  }

  .counter-value {
    color: #020202;
    font-family: "Work Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
  }

  .single-product-coloum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0%;
    padding-right: 3%;
    margin-bottom: 3%;
  }

  .PuffLoaderCart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .checkout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center horizontally */

    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20px;
  }

  .subtotal-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-right: 3%;
  }

  .subtotal-text {
    font-family: Work Sans;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 16px;
  }

  .price-text {
    font-family: Work Sans;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: -0.32px;
  }

  .checkout-button {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(252, 219, 204);
    border-radius: 10px;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.32px;
    margin-bottom: 30px;
  }
}
