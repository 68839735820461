@media (min-width: 768px) {
  .orderConfirmation-container {
    margin-top: 177px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .orderConfirmation-Right-Side {
    width: 50%;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
  }
  .orderConfirmation-mobile {
    display: none;
  }
  .orderConfirmation-Left-Side {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .order-Confirmed-title {
    margin-top: 20px;
    font-size: 40px;
    font-family: Work Sans;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: rgba(252, 219, 204, 1);
    display: flex;
    justify-content: center;
  }
  .order-Confirmed-text-body {
    margin-top: 20px;
    font-size: 20px;
    font-family: Work Sans;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.32px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 433px;
  }
  .continue-shopping-button-OC {
    margin-top: 20px;
    width: 424px;
    height: 71px;
    background-color: rgb(253, 220, 205);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(2, 2, 2, 1);
  }
  .track-My-Order-button-OC {
    margin-top: 25px;
    width: 424px;
    height: 71px;
    background-color: transparent;
    border: 2px solid rgb(253, 220, 205); /* Set border */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(2, 2, 2, 1);
  }
  .orderConfirmation-corect-sign {
    color: rgb(252, 219, 204);
    font-size: 350px;
  }
}
@media (max-width: 767px) {
  .orderConfirmation-container {
    display: none;
  }
  .orderConfirmation-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
  }
  .orderConfirmation-mobile-container {
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
    padding-top: 115px;
    padding-bottom: 50px;
  }
  .orderConfirmation-pc-body {
    margin-top: 40px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-Confirmed-title {
    margin-top: 20px;
    font-size: 28px;
    font-family: Work Sans;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: rgba(252, 219, 204, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-Confirmed-text-body {
    margin-top: 20px;
    font-size: 16px;
    font-family: Work Sans;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.32px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 328px;
  }
  .continue-shopping-button-OC {
    margin-top: 20px;
    width: 353px;
    height: 48px;
    background-color: rgb(253, 220, 205);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(2, 2, 2, 1);
  }
  .track-My-Order-button-OC {
    margin-top: 25px;
    width: 353px;
    height: 48px;
    background-color: transparent;
    border: 2px solid rgb(253, 220, 205); /* Set border */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: rgba(2, 2, 2, 1);
  }

  .orderConfirmation-corect-sign {
    color: rgb(252, 219, 204);
    font-size: 274px;
  }

  .orderSummery-Mobile {
    margin-top: 20px;
    width: 100%;
    font-family: Work Sans;
    font-size: 16px;
    line-height: 16px;
    flex-direction: row;
    margin-bottom: 60px;
  }
  .orderConfirmation-Title {
    height: 5cqb;
    width: 100%;
    background-color: rgb(229, 229, 229);
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: space-between;
    font-weight: 600;
  }
  .orderConfirmation-details {
    height: 5cqb;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: 20px;
  }
  .orderConfirmation-totalPrice {
    margin-top: 20px;
    height: 5cqb;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: space-between;
    font-weight: 600;
  }
  .orderConfirmation-Delivery {
    margin-top: 20px;
    height: 5cqb;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: space-between;
    font-weight: 600;
    flex-direction: column;
  }
  .orderConfirmation-addressDate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
  }
  .orderConfirmation-addressDateWidth {
    margin-top: 10px;
    width: 40%;
  }
}
