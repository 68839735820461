.image-stack {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .layer {
 
    position: absolute;
    width: 90%; /* Ensure images fill the container */
    
  }
  
  .enlarged{
   
    position: absolute;
    display: flex;
    width: 110%; /* Adjust width as needed */
    padding-bottom: 20%;
  }

  .image-stack2 {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .layer2 {
    position: absolute;
    width: 90%; /* Ensure images fill the container */
    
  }
  .enlarged2{
   
    position: absolute;
    display: flex;
    width: 85%; /* Adjust width as needed */
    padding-bottom: 15%;
    padding-left: 0%;
  }



  .image-stack3 {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .layer3 {
    position: absolute;
    width: 90%; /* Ensure images fill the container */
    
  }
  .enlarged3{
   
    position: absolute;
    display: flex;
    width: 110%; /* Adjust width as needed */
    padding-left: 5%;
   
  }


  .image-stack4 {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .layer4 {
    position: absolute;
    width: 100%; /* Ensure images fill the container */
    
  }
  .enlarged4{
    position: absolute;
    display: flex;
    width: 100%; /* Adjust width as needed */
    padding-left: 5%;
    padding-bottom: 10%;
   
  }


  .image-stack5 {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .layer5 {
    position: absolute;
    width: 100%; /* Ensure images fill the container */
    
  }
  .enlarged5{
    position: absolute;
    display: flex;
    width: 100%; /* Adjust width as needed */
    padding-bottom: 10%;
   
  }


  .image-stack6 {
    position: relative;
    width: 90%; /* Adjust width as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .layer6 {
    position: absolute;
    width: 100%; /* Ensure images fill the container */
    
  }
  .enlarged6{
    position: absolute;
    display: flex;
    width: 100%; /* Adjust width as needed */
    padding-bottom: 10%;
   
  }



  
  