@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@media (min-width: 768px) {
    .FAQs-Container-PC{
        width: 100%;
        padding-top: 280px;
        display:flex;
        flex-direction: row;
        padding-left: 10%;
        padding-right: 10%;
        margin-bottom: 30px;
        gap: 3%;
    }
    .FAQs-Container-Mobile{
        display: none;
    }
    .left-buttons{
        display: flex;
        flex-direction: column;
       
    }
    .profile-left-button{
        display: flex;
        flex-direction: column;
    }   
    a{
        text-decoration: none;
        color: inherit;
    }
    .profile-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid #E3A689;
        padding: 8px;
        margin: 0 auto; 
        font-weight: 400;
        line-height: 24px;
        height: 62px; 
        width: 127px; 
        font-size: 22px;
        text-align: center;
    }
    .profile-box.arabic{
        font-family: "Cairo";
    }
    .profile-box.active {
        background-color: rgb(252, 219, 204); /* Set background color for the active button */
        color: black; /* Set text color for the active button */
        border: none;
    }
    .FAQ-Text-Container-PC{
        width: 100%;

    }
    .single-question-container{
        display: flex;
        flex-direction: column;
        font-family: Work Sans;
        font-size: 24px;
        line-height: 28px;
    }
    .single-question-container.arabic{
       font-family: "Cairo"; 
    }
    .question-title-faq{
        font-weight: 600;
    }
    .question-title-faq.arabic{
        font-weight: 500;
    }
    .question-answer-faq{
        font-weight: 400;
        margin-bottom: 30px;
    }
    .question-answer-faq.arabic{
        font-weight: 300;
    }
    .question-answer-faq-case{
        font-weight: 400;
        margin-bottom: 0px;
    }
    .question-answer-faq-case.arabic{
        font-weight: 300;
    }
}

@media (max-width: 767px) {

    .FAQs-Container-PC{
      display: none;
    }
    .FAQs-Container-Mobile{
        padding-top: 150px;
        display:flex;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
        margin-bottom: 30px;
        min-height: 500px;
    }
    .faqs-left-buttons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width:100%;
        height: 170px;
        gap: 2%;
        
    }  
    .faqs-left-button-coloum{
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 33.3%;
        
    }

   
    .faqs-button a{
        text-decoration: none;
        color: inherit;
       
    }
    .faq-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        border: 1px solid #E3A689;
        font-weight: 400;
        line-height: 16px;
        font-size: 16px;
        text-align: center;
        height: 36.5px;
        width: 100%; /* Adjusted width to fit the container */
       
    }
    .faq-box.arabic{
        font-family: "Cairo";
    }
    .faq-box.active {
        background-color: rgb(252, 219, 204); /* Set background color for the active button */
        color: black; /* Set text color for the active button */
        border: none;
    }
    .FAQ-Text-Container-PC{
        width: 100%;

    }
    .single-question-container{
        display: flex;
        flex-direction: column;
        font-family: Work Sans;
        font-size: 16px;
        line-height: 20px;
    }
    .single-question-container.arabic{
        font-family: "Cairo"; 
     }
    .question-title-faq{
        font-weight: 600;
    }
    .question-title-faq.arabic{
        font-weight: 400;
    }
    .question-answer-faq{
        font-weight: 400;
        margin-bottom: 18px;
    }
    .question-answer-faq.arabic{
        font-weight: 300;
    }
    .question-answer-faq-case{
        font-weight: 400;
        margin-bottom: 0px;
    }
    .question-answer-faq-case.arabic{
        font-weight: 300;
    }

}