@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
  /* Hidden on small screens, flex on medium and larger screens */
  @media (min-width: 768px) {
    .pc-navbar-announcement-stripe {
      height: 40px;
      /* Set the desired height */
      overflow: hidden;
      display: flex;
      align-items: center;
      /* Center vertically */
    }

    .announcement-text-container {
      width: 100%;
      animation: scrollText 20s linear infinite;
      /* Adjust animation duration */
    }

    @keyframes scrollText {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(100%);
      }
    }

    .announcement-text {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-top: 15PX;
      direction: rtl;
      unicode-bidi: embed;
    }

    .pc-navbar-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      display: flex;
      position: fixed;
      /* Add this line */
      top: 0;
      /* Add this line */
      z-index: 1000;
      /* Add this line */
    }

    .pc-navbar-announcement-stripe {
      background-color: rgba(252, 219, 204, 1);
    }

    .pc-navbar-main {
      background-color: white;
      width: 100%;
      height: 90px;
      box-shadow: 0px 4px 20px rgb(217, 209, 205);
      display: flex;
      align-items: center;
      padding-left: 2%;
      padding-right: 2%;
      gap: 2%;
    }

    .pc-natinda-logo {
      width: 25%
    }

    .pc-natinda-image {
      width: 100%;
    }

    .pc-search-bar {
      width: 100%;
    }

    .pc-navbar-right-align {
      width: 25%;
      display: flex;
      align-items: center;
         justify-content: center;
    gap: 18%;
    }

    .pc-navbar-sign-text {

      text-decoration: none;
      color: inherit;
    }

    .pc-navbar-menu {
      background-color: rgba(248, 240, 236, 1);
      width: 100%;
      height: 47px;
      /* filter: drop-shadow(0px 2px 3px rgb(0, 0, 0)); */
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 8%;
      padding-right: 8%;
      gap: 2%;
    }

    .pc-navbar-menu-text {
      font-family: Work Sans;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.32px;
      text-align: center;
      text-decoration: none;
      color: inherit;
    }
    .pc-navbar-menu-text.arabic {
      font-family: 'Cairo' !important;
      font-weight: 600;
      font-size: 20px;
    }

    .mobile-navbar-container {
      display: none;
    }

    .mobile-hamburger-menu {
      display: none;
    }

    .profile-dropdown {
      position: relative;
    }

    .profile-dropdown-menu {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      width: 150px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }

    .profile-dropdown-menu .dropdown-item {
      display: block;
      padding: 8px 12px;
      font-size: 14px;
      color: #333;
      text-decoration: none;
      transition: background-color 0.3s ease;
    }

    .profile-dropdown-menu .dropdown-item:hover {
      background-color: #f5f5f5;
    }

    .profile-dropdown-menu .divider {
      margin: 8px 0;
      border-top: 1px solid #ccc;
    }

    .cart-container {
      position: relative;
    }

    .cart-icon-container {
      display: flex;
      align-items: center;
      /* Center vertically */
      position: relative;
    }

    .cart-count {
      position: absolute;
      top: -10px;
      right: -20px;
      background-color: black;
      color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
    }
    .translateContainer{
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 10%;
      font-family: "Cairo";
      font-size: 18px;
      font-weight: 400;
    }

    .translateContainer.arabic{
      font-family: Work Sans;
    }

  }

  @media (max-width: 767px) {

    .mobile-navbar-container {
      position: fixed;
      /* Add this line */
      top: 0;
      /* Add this line */
      z-index: 1000;
      /* Add this line */
      width: 100%;
    }

    .pc-navbar-announcement-stripe {
      height: 40px;
      /* Set the desired height */
      overflow: hidden;
      display: flex;
      align-items: center;
      /* Center vertically */
      background-color: rgba(252, 219, 204, 1);
    }

    .announcement-text-container {
      width: fit-content;
      animation: scrollText 15s linear infinite;
      /* Adjust animation duration */
     }

    @keyframes scrollText {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(100%);
      }
    }

    .announcement-text {
      white-space: nowrap;
      margin-top: 18px;
      align-items: center;
      display: flex;
      font-size: 12px;
      direction: rtl;
      unicode-bidi: embed;
    }
.announcement-text.arabic{
  direction:ltr;
}

    .pc-navbar-container {
      display: none;
    }

    .mobile-navbar-announcement-stripe {
      background-color: rgba(252, 219, 204, 1);
    }

    .mobile-navbar-main {
      background-color: rgb(255, 244, 240);
      width: 100%;
      height: 75px;
      box-shadow: 0px 4px 20px rgb(217, 209, 205);
      display: flex;
      align-items: center;
      padding-left: 5%;
      padding-right: 5%;


    }

    .mirror {
      transform: scaleX(-1); /* Mirrors the image horizontally */
    }

    .left-align {
      width: 20%;
    }

    .mobile-hamburger-icon {
      width: 30%;
    }


    .mobile-natinda-logo {
      width: 60%;
      justify-content: center;
      display: flex;
    }

    .mobile-natinda-image {
      width: 70%;

    }

    .right-align {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 25%;
      width: 20%;

    }

    .mobile-navbar-container.menu-open {
      display: none;
    }



    .mobile-hamburger-menu {
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 1050;
      display: none;
      background-color: rgba(252, 219, 204, 1);
      padding: 7%;
      margin-top: 75px;
    }


    .mobile-hamburger-menu.menu-open {
      display: flex;
    }

    .close-button {
      /* position: absolute; */
      background: none;
      border: none;
      color: black;
      cursor: pointer;
      width: 35%;
      height: 40px;
      /* top: 50px;
      left: 3%; */
    }

    .mobile-hamburger-menu-text-container {
      display: flex;
      flex-direction: column;
      width:100%


    }
    .mobile-hamburger-menu-black-line{
     padding-left: 10%;
     padding-right: 10%;
     margin-bottom: 20px;
     height: 25px;
     color: inherit;
    }

    .mobile-hamburger-menu-text {
      font-family: Work Sans;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.32px;
      text-decoration: none;
      color: inherit;
      height: 50px;
      display: flex;
      justify-content: space-between;
    }
    .mobile-hamburger-menu-text.arabic{
      font-family: "Cairo";
    }

    .search-and-close {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
    }


    .cart-container-nav-bar {
      position: relative;
    }

    .cart-icon-container-nav-bar {
      display: flex;
      align-items: center;
      position: relative;
    }

    .cart-count-nav-bar {
      position: absolute;
      top: -10px;
      right: -12px;
      background-color: black;
      color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
    }
    .translateContainer{
      cursor: pointer;
      display: flex;
      flex-direction: row;
      gap: 1%;
      font-family: "Cairo";
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .translateContainer.arabic{
      font-family: Work Sans;
    }
  }