.single-product-container {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 100px;
  margin-top: 177px;
}

.product-upper-page {
  display: flex;
  flex-direction: row;
  gap: 130px;
  margin-top: 50px; /* HENA ZAYDA */
}

/* gift card css **********************************/

.gift-card-container {
  display: flex;
  flex-direction: row;
  gap: 130px;
  margin-top: 50px;
  height: 491px;
}

.gift-card-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.gift-card-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  width: 50%;
}

.gift-card-image img {
  height: 238px;
  object-fit: contain;
}

.price-selection-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 10px;
}
.price-option {
  width: calc(25% - 20px); /* 25% for 4 items, minus margin */
  height: 42.75px;
  margin: 5px;
  border: 1px solid #f6d4c4;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #f6d4c4;
}
.price-option.selected {
  background-color: #f6d4c4;
  color: white;
}

/* Swiper css **********************************/
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  gap: 40px;
  position: relative;
}
.main-photo .swiper-slide {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.main-photo .swiper-slide img {
  height: 410px;
  object-fit: contain;
}

.thumbnail .swiper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail .swiper-slide button {
  border: none;
  background-color: transparent;
}

.thumbnail .swiper-slide img {
  height: 92px;
  width: 92px;
  /* object-fit: contain; */
}

/* ************************************************************************************************/

.product-upper-page-column {
  display: flex;
  flex-direction: column;
  gap: 1.5%;
  width: 50%;
}

.path {
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  display: flex;
}

.product-like {
  display: flex;
  justify-content: flex-end;
}
.product-bundle-save {
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
}

.single-product-name {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-size: 32px;
}
.single-product-description {
  color: #231f20;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-size: 16px;
}

hr {
  margin: 0px;
}
.single-product-hearts-container {
  display: flex;
  align-items: center;
  gap: 1px;
}
.single-product-num_reviews {
  color: #020202;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  font-size: 12px;
  margin-left: 5px;
}

.product-description-rest {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  font-size: 22px;
  color: #818181;
}

/* Counter and add to cart **********************************/

.quantity-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
  margin-top: 20px;
}
.counter-box-cart {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #ece095;
  height: 48px;
  width: 120px;
}
.counter-container-cart {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.counter-container-cart button {
  background: none;
  border: none;
  cursor: pointer;
  color: #020202;
  font-family: "Work Sans";
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  margin: 0 8px;
  outline: none;
}
.counter-value {
  color: #020202;
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
}
.single-product-add-to-cart {
  border-radius: 10px;
  color: #020202;
  font-family: "Work Sans";
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 70%;
  height: 48px;
}
.single-product-add-to-cart:hover {
  background: #eddcb8;
  color: #231f20;
}

.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1.5px solid;
  border-color: inherit;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

/* ***********************************************/
.product-icons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

.single-product-icons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.single-product-icons-container img {
  height: 92px;
  width: 92px;
}
.single-product-icons-container span {
  font-family: "Work Sans";
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: -0.32px;
}

/* End of product column ************************************************************************************************/

/* Accordion ************************************************************************************************/

.acc-container {
  margin: 50px;
}
.single-product-title {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.32px;
  font-size: 30px;
}
.single-product-details {
  color: #000000;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.32px;
}
.single-product-details strong {
  font-weight: 600;
}

.single-product-details p {
  margin: 0px;
}
.accordion svg {
  color: #000000;
}

.single-product-faq-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.single-product-faq-question {
  color: #000000;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
}
.single-product-faq-answer {
  color: #000000;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.32px;
}

/* swiper ************************************************************************************************/

.accordion-swiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-swiper .swiper-slide img {
  width: 100%;
}

.image-text-title {
  font-family: "Work Sans";
  font-size: 40px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  margin-bottom: 30px;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translateY(-50%);
  width: 30%;
  font-family: "Work Sans";
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  padding: 0 20px;
}

/* End Accordion ************************************************************************************************/
/* Pait it with ************************************************************************************************/

.pair-it-with {
  color: #231f20;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 48px;
  text-align: center;
  margin: 20px 0px;
}
.pair-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 60px;
  position: relative;
}
.pair-name {
  color: #231f20;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.32px;
  font-size: 32px;
  margin-top: 20px;
}
.pair-product .hearts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pair-button button {
  border-radius: 10px;
  color: #020202;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  outline: none;
  width: 441px;
  height: 48px;
}
/* End Pait it with  ************************************************************************************************/

.reviews-center {
  color: #231f20;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.32px;
  font-size: 40px;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 25px;
}
.reviews-hearts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.comment-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #ece095;
  height: 48px;
  width: fit-content;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  padding: 15px 50px;
  margin-bottom: 20px;
  background-color: transparent;
}
.write-review-container {
  margin: 50px;
}

.question {
  color: #818181;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.32px;
  margin-top: 20px;
}

.answer {
  background-color: #fbf9ea;
  width: 100%;
  border-radius: 10px;
  height: 48px;
  margin-top: 7px;
  padding: 10px;
  border: none;
}
.answer-big {
  background-color: #fbf9ea;
  width: 100%;
  border-radius: 10px;
  height: 208px;
  margin-top: 7px;
  padding: 10px;
  vertical-align: top;
  border: none;
}

.MuiSnackbar-root svg {
  width: 1.5rem;
  height: 1.5rem;
}
.answer:focus,
.answer-big:focus {
  outline: none;
}
.rating {
  color: #818181;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.32px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.select-heart {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 3px;
}
.single-product-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #ece095;
  height: 48px;
  width: 30%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  margin-top: 20px;
  padding: 15px 50px;
  border: none;
}
.single-prouduct-reviews {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.review-title {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.32px;
  margin-top: 25px;
}
.review-comment {
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.32px;
}

.reviews-hearts-container {
  display: flex;
  align-items: center;
  margin-left: -3px;
}

.single-product-mobile {
  display: none;
}

/* Bundle Pop up ************************************************************************************************/

.bundle-pop-up {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  z-index: 1101;

  display: flex;

  width: 1000px;
}

.bundle-pop-up img {
  flex: 1;
  height: 634px;
}
.bundle-pop-up-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
}

.bundle-pop-up-x {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.bundle-pop-up-x img {
  width: 30px;
  height: 30px;
  flex: none;
  cursor: pointer;
}

.bundle-pop-up-column span {
  font-family: "DM Serif Display";
  font-size: 50px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.32px;
  text-align: left;
}

.bundle-pop-up-sub {
  font-family: "Work Sans";
  font-size: 36px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
}
.bundle-pop-up-button-div {
  width: 100%;
  display: flex;
}
.bundle-pop-up-button {
  border-radius: 10px;
  background-color: transparent;
  height: 56px;
  width: 132px;
  color: #020202;

  font-family: "Work Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border: 2px solid #020202;
}
.bundle-pop-up-x-white {
  display: none;
}
tamara-widget {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.wrap-tamara {
  padding: 0px 20px;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  .single-product-web {
    display: none;
  }
  .single-product-mobile {
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    padding-top: 135px;
  }

  .swiper-pagination-bullet {
    background-color: grey;
    width: 5px;
    height: 5px;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: black;
  }
  .path {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.32px;
    display: block;
    font-family: "Work Sans";
    padding: 0px 20px;
    margin-bottom: 20px;
  }
  .MobileImageSwiperpadding {
    padding: 0px 20px;
    margin-bottom: 20px;
    position: relative;
  }

  .product-bundle-save {
    padding: 0px 20px;
    font-size: 16px;
    line-height: 16px;
  }

  .single-product-name {
    font-size: 18px;
    line-height: 16px;
    padding: 0px 20px;
    margin-bottom: 5px;
  }
  .single-product-description {
    font-size: 16px;
    line-height: 16px;
    padding: 0px 20px;
    margin-bottom: 0px;
  }
  .product-description-rest {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    padding: 0px 20px;
    margin-bottom: 0px;
  }
  .single-product-hearts-container {
    padding: 0px 20px;
  }
  .mobile-counter-button-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 20px;
    box-shadow: 0px 4px 3px rgb(217, 209, 205);
  }

  .quantity-row {
    display: flex;
    gap: 7px;
    padding: 0;
    width: 100%;
    margin-top: 0px;
    padding: 0px 20px;
  }

  .single-product-add-to-cart {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    padding: 10px 20px;
    width: 100%;
    height: 48px;
    background-color: white;
  }
  .counter-container {
    gap: 10px;
    width: 100%;
  }
  .counter-container button {
    font-size: 16px;
  }
  .counter-value {
    font-size: 16px;
  }

  .product-icons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
    padding: 0 20px;
    gap: 0px;
  }

  .single-product-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 20%; /* Set a fixed percentage width */
  }

  .single-product-icons-container img {
    height: 60px;
    width: 60px;
  }

  .single-product-icons-container span {
    font-size: 10px;
    text-align: center;
    line-height: 12px;
    font-weight: 500;
  }

  .single-product-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    padding: 0px 20px;
    margin-bottom: 5px;
  }
  .single-product-details {
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    padding: 0px 20px;
    margin-bottom: 0px;
  }
  .single-product-details ul {
    padding: 0px 20px;
  }

  .single-product-details-acc {
    font-family: "Work Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
  }

  .hero-ingredients {
    color: #c6bc7e;
    font-family: "Work Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-bottom: 7px;
  }

  .reviews-center {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.3199999928474426px;
  }
  .reviews-hearts {
    margin-top: 7px;
    margin-bottom: 0px;
  }
  .comment-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #ece095;
    height: 48px;
    padding: 10px;
    width: fit-content;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    margin-top: 7px;
  }

  .single-product-faq-question {
    font-size: 16px;
    line-height: 16px;
    padding: 0px 20px;
  }
  .single-product-faq-answer {
    font-size: 16px;
    line-height: 18px;
    padding: 0px 20px;
  }

  .write-review-container {
    margin: 20px;
  }
  .single-product-submit {
    width: 50%;
    margin-top: 20px;
  }

  .select-heart svg {
    height: 35px;
    width: 35px;
  }

  .num_reviews {
    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: -0.32px;
  }
  .gift-card-image-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    height: 409px;
    margin: 0px 20px;
  }

  .gift-card-image-mobile img {
    height: 171px;
  }

  .price-selection-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-top: 20px;
  }

  .price-option {
    width: calc(33.33% - 10px);
    height: 37px;
    margin: 5px;
    font-size: 16px;
    text-align: center;
    color: #f6d4c4;
  }

  .fixed-add-to-cart {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  .comment-box a {
    text-decoration: none;
    color: black;
  }
  .strikethrough {
    position: relative;
    display: inline-block;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1.5px solid;
    border-color: inherit;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  /* Bundle Pop up ************************************************************************************************/

  .bundle-pop-up {
    top: 50%;
    left: 50%;
    flex-direction: column;
    width: 290px;
  }

  .bundle-pop-up img {
    flex: 1;
    height: 246px;
    width: 290px;
  }
  .bundle-pop-up-column {
    align-items: flex-start;
    padding: 15px;
    gap: 25px;
  }

  .bundle-pop-up-x {
    display: none;
  }

  .bundle-pop-up-column span {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.32px;
    text-align: left;
  }

  .bundle-pop-up-sub {
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-align: left;
  }

  .bundle-pop-up-button {
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid #020202;

    color: #020202;
    height: 48px;
    width: 101px;
    padding: 10px;
    font-size: 14px;
    line-height: 16px;
  }

  .bundle-pop-up-x-white {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .bundle-pop-up-x-white img {
    width: 30px;
    height: 30px;
  }
}
