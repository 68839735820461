@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

.arabic-font main,
.arabic-font main * {
  font-family: "Cairo" !important;
}
.quiz-start-web-view {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #fff5f1;
  margin-top: 177px;
}
.quiz-start-mobile-view {
  display: none;
}

.quiz-start-button {
  border-radius: 10px;
  text-transform: uppercase;
  color: #020202;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  background-color: #fcdbcc;
  border: none;
  width: 61%;
  height: 71px;
  margin-top: 20px;
}

.quiz-start-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.quiz-start-head {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32px;
  color: #231f20;
}

.quiz-start-sub {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.41px;
  padding: 0px 100px;
  color: #020202;
  text-align: center;
}

.quiz-start-right {
  flex: 1;
}

.quiz-start-right img {
  height: 637px;
}

.quiz-web-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  margin-top: 177px;
  padding: 80px;
}

.quiz-question {
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.32x;
  text-align: center;
  width: 50%;
  margin-bottom: 70px;
}

.quiz-text-input {
  position: relative;
  width: 40%;
  margin-bottom: 70px;
}

.quiz-text-input input[type="text"] {
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  font-size: 18px;
  padding: 5px 0;
  width: 100%;
}

.quiz-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 70px;
}

.quiz-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 20px); /* Adjust width as needed */
  border: 2px solid #fcdbcc;
  background-color: transparent; /* Set default background color */
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  font-family: "Work Sans";
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.72px;
  font-size: 24px;
  height: 71px;
  cursor: pointer; /* Add cursor pointer for interaction */
}
.quiz-option.selected {
  background-color: #fcdbcc; /* Change background color when selected */
}

.quiz-back-next-container {
  display: flex;
  justify-content: center;
  gap: 4%;
  width: 50%;
}

.quiz-back-next-container button {
  border-radius: 10px;
  color: #020202;
  font-family: "Work Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  border: 2px solid #fcdbcc;
  width: 100%;
  height: 71px;
}
.quiz-mobile-view {
  display: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(254, 244, 240, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
}

.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 60%;
  background: white;
  border-radius: 10px;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  align-items: center;
}

.modal-image {
  flex: 1;
}
.modal-image img {
  height: 634px;
  width: 100%;
}

.modal-actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 30px;
  font-family: "DM Serif Display";
  font-size: 48px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.32px;
  text-align: left;
}

.modal-actions-sub {
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.32px;
  text-align: left;
  margin-top: 40px;
}

.modal-actions-first-button {
  border-radius: 10px;
  text-transform: uppercase;
  color: #020202;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;

  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  background-color: #fcdbcc;
  border: none;
  height: 71px;
  width: 100%;
  margin-top: 40px;
}

.modal-actions-second-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  border: 2px solid #fcdbcc;
  padding: 15px 50px;
  height: 71px;
  width: 100%;
  margin-top: 20px;
  color: #020202;
  font-family: "Work Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
}

.model-backdrop-mob {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 767px) {
  .quiz-start-web-view {
    display: none;
  }
  .quiz-start-mobile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff5f1;
    padding-top: 135px;
  }
  .quiz-start-mobile-view img {
    width: 100%;
    height: 387px;
    margin-bottom: 20px;
  }
  .quiz-start-head-mob {
    font-family: "DM Serif Display";
    font-size: 28px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.32px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .quiz-start-sub-mob {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-align: center;
    color: #818181;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .quiz-start-button {
    font-size: 16px;
    padding: 10px;
    width: 90%;
    height: 48px;
    margin-bottom: 20px;
  }

  .quiz-web-view {
    display: none;
  }

  .quiz-mobile-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 60vh;
    padding-top: 115px;
  }

  .quiz-mobile-big-container {
    padding: 20px;
  }

  .quiz-progress-container {
    width: 100%;
    height: 23px;
    background-color: rgba(252, 219, 204, 0.3); /* #FCDBCC with 30% opacity */
  }
  .quiz-progress-bar {
    height: 23px;
    background-color: #fcdbcc;
    transition: width 0.3s ease-in-out;
  }

  .quiz-question {
    font-size: 28px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
  }
  .quiz--mobile-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .quiz--mobile-container button {
    border-radius: 10px;
    color: #020202;
    font-family: "Work Sans";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: 1px solid #fcdbcc;
    height: 48px;
  }
  .quiz-text-input {
    width: 100%;
  }

  .quiz-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 70px;
    gap: 10px;
  }
  .quiz-option {
    width: 100%;
    padding: 10px;
    margin: 0px;
    line-height: 24px;
    letter-spacing: -0.72px;
    font-size: 16px;
    height: 48px;
    border: 1px solid #fcdbcc;
  }

  .modal-backdrop-web {
    display: none;
  }
  .model-backdrop-mob {
    display: flex;
    flex-direction: column;
    padding-top: 115px;
  }

  .modal-image-mob img {
    width: 100%;
    height: auto;
  }
  .modal-head {
    font-family: "DM Serif Display";
    font-size: 32px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.32px;
    padding: 0px 20px;
    margin-top: 40px;
  }

  .modal-sub {
    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.32px;
    padding: 0px 20px;
    margin-top: 20px;
  }

  .modal-actions-first-button-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #fcdbcc;
    border: none;
    color: #020202;

    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    width: 100%;
    height: 48px;
    margin-top: 40px;
  }

  .modal-actions-second-button-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid #fcdbcc;
    color: #020202;

    font-family: "Work Sans";
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    width: 100%;
    height: 48px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
