.receipt-container {
    width: 100%;
    padding-top: 250px;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 250px;

}

.receipt-head{
    text-align: center;

    font-family: "Work Sans";
    font-size: 46px;
    font-weight: 500;

    margin-bottom: 20px;
}

.receipt-header {
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
}

.receipt-natinda-logo{
    width: 280px;
}

.receipt-wogoh-baraka{
    width: 180px;
}


.black-line {
    display: flex;
    height: 0.75px;
    background-color: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 2%;
    margin-right: 2%;
}

.billing-company-info-container {
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    
}

.billing-coloum {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 30%;
}
.billing-coloum2{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5%;
    text-align: left;
    width: 30%;
}

.billing-info-title {
    font-family: "Work Sans";
    font-size: 18px;
    font-weight: 500;

}
.billing-info-body{
    font-family:"Work Sans";
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    
}
.billing-info-body2{
    font-family: Work Sans;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    text-align: right;
}
.receipts-order-information{
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    gap: 10px;
}
.receipts-order-title{
    font-family: "Work Sans";
    font-weight: 600;
    font-size: 18px;

}

.receipts-order-details{
    display: flex;
    flex-direction: column;

    font-family: "Work Sans";
    font-size: 12px;
    font-weight: 500;
    line-height: 12.1px;

}

.receipts-order-items{
    display: flex;
    flex-direction: column;
    padding-left: 2%;
    padding-right: 2%;
    gap: 10px;
}

.receipts-order-items-container{
    display: flex;
    justify-content: space-between;
}

.receipts-order-items-single{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    gap: 20px;

    font-size: 16px;
    font-weight: 400;
    line-height: 17.27px;
}

.receipts-order-items-single-body{

    font-size: 14px;
    font-weight: 400;
    line-height: 17.27px;
}

.receipts-final-1{
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;

    font-size: 16px;
    font-weight: 400;
    line-height: 17.27px;
}

.receipts-final-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.receipts-final-2{
    display: flex;
    justify-content: flex-end;
    text-align: right;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    
}


@media (max-width: 767px) {

    .receipt-container {
        padding-top: 150px;
        padding-left: 3%;
        padding-right: 3%;
        padding-bottom: 250px;
    }

    .receipt-natinda-logo{
        width: 180px;
    }
    
    .receipt-wogoh-baraka{
        width: 100px;
    }
    .receipts-order-items{
        gap: 0px;
    }
    .receipts-order-items-container{
        gap: 10px;
    }

    .receipts-order-items-single{

        gap: 10px;
        font-size: 14px;
        /* line-height: 17.27px; */
    }
}